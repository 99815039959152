import React, { useState, useRef, useEffect } from 'react';

const TextContainer = ({ text }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const containerRef = useRef(null);
    const contentRef = useRef(null);

    useEffect(() => {
        const container = containerRef.current;
        const content = contentRef.current;
        const observer = new ResizeObserver((entries) => {
            const { height } = entries[0].contentRect;
            setIsExpanded(height < container.clientHeight);
        });

        observer.observe(content);

        return () => observer.disconnect();
    }, []);

    const toggleExpansion = () => {
        setIsExpanded(!isExpanded);
    };

    // Check if the text is empty or already fits within 2 lines
    const shouldHideButton = !text || (text.length <= 100);

    return (
        <>
            <div ref={containerRef} className={`text-container ${isExpanded ? 'expanded' : ''}`}>
                <p ref={contentRef}>{text}</p>
            </div>
            {!shouldHideButton && (
                <div className='expandedButton pb-2'>
                    {isExpanded ? (
                        <>
                            <a href="#" onClick={toggleExpansion}>See Less</a>
                        </>
                    ) : (
                        <>
                            <a href="#" onClick={toggleExpansion}>See More</a>
                        </>
                    )}
                </div>
            )}
        </>
    );
};

export default TextContainer;