import { createTheme } from '@mui/material';

const theme = createTheme({
  palette: {
    error: {
      main: '#D43C3C',
    },
    primary: {
      main: '#FFBA00',
    },
    tonal_03: '#18293F',
  },
  typography: {
    fontFamily: ['"Inter"', 'sans-serif'].join(','),
    text_s: {
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: '400',
    },
    text_m_bold: {
      fontSize: '16px',

      lineHeight: '20px',
      fontWeight: 600,
      letterSpacing: '0.01em',
    },
    text_l_bold: {
      fontSize: '21px',
      lineHeight: '28px',
      fontWeight: 600,
    },
  },
  components: {
    MuiTooltip: {
      defaultProps: {
        arrow: true,
      },
      styleOverrides: {
        tooltip: ({ theme }) => ({
          ...theme.typography.text_s,
          backgroundColor: theme.palette.tonal_03,
          padding: '8px 12px',
        }),
        arrow: ({ theme }) => ({
          color: theme.palette.tonal_03,
        }),
      },
    },
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.common.white,
          textTransform: 'none',
          borderRadius: '8px',
          transition: 'all .6s ease-in-out',
          '&.Mui-selected': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.black,
          },
        }),
      },
    },
  },
});

export default theme;
