import React, { Fragment } from "react";
import { Footer } from "./Footer";
import { Header } from "./Header";

import MobHeader from "./MobHeader";
import "../App.css";
import { useParams } from 'react-router-dom';

import { User } from "./User";

export const Profile = () => {
  const { id } = useParams();
  return (
    <Fragment>
      <div className="App">
        <Header />
        <MobHeader />
        <User leaderId={id}/>

        <Footer />
      </div>
    </Fragment>
  );
};
