import styles from "../../css/setting.module.css";

export const InformationMessageModal = ({ isInfoModalOpen, closeInfoModal, headingContent, bodyContent }) => {

  return (
    isInfoModalOpen && (
      <>
        <div className={`${styles.overlay}`}></div>
        <div className={`${styles.modal}`}>
          <main className={`${styles.modal__main}`}>
            <h2 className={`${styles.modalSection}`}>{headingContent}</h2>
            <p className={`${styles.modalparagrapgh}`}>
              {bodyContent}
            </p>
            <div >
              <input
                type="button"
                value={'OK'}
                className={`${styles.buttonOk}`}
                onClick={() => {
                  closeInfoModal()
                }
                }
              />
            </div>

          </main>
        </div>
      </>
    )
  )

} 
