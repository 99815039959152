import React from 'react'

export const Homecotnent = () => {
  return (
    <>
      <div className='content-section' id='homebanner'>
        <div className='container-f'>
          <h1>Welcome to the <span>Tycoon Leaderboard</span> for Binance futures</h1>
          <p>Search for your favorite traders to follow their trades in real-time.</p>
          <div className='home-content-buttn'>
          </div>
        </div>
      </div>
    </>
  )
}
