import { SvgIcon } from '@mui/material';

const NotSharingPositionIcon = (props) => (
  <SvgIcon {...props}>
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='16.5' cy='16.5' r='2' stroke='#D43C3C' />
      <circle cx='16.5' cy='6.5' r='2' stroke='#D43C3C' />
      <circle cx='7.5' cy='11.5' r='2' stroke='#D43C3C' />
      <path d='M14.5 7.5L9.5 10.5' stroke='#D43C3C' strokeLinecap='round' />
      <path d='M9.5 12.5L14.5 15.5' stroke='#D43C3C' strokeLinecap='round' />
      <path d='M7 1L1 7' stroke='#D43C3C' strokeLinecap='round' />
      <path d='M1 1L7 7' stroke='#D43C3C' strokeLinecap='round' />
    </svg>
  </SvgIcon>
);

export default NotSharingPositionIcon;
