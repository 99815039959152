import React, { useEffect, useRef, useState, Fragment } from "react";
import { useLocation } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import logo from "../Components/Images/logoBeta.png"

export const UnverifiedEmailPage = () => {
  const {
    logout
  } = useAuth0();

  const [infoMessage, setInfoMessage] = useState("");

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const encodedUserId = searchParams.get('userId');
  const userID = decodeURIComponent(encodedUserId);


  const resendEmail = async () => {
    if (userID) {
      axios
        .get(`${window._env_.REACT_APP_API_URL}/Users/ResendVerificationEmailFromAuth0?userId=${userID}`, {
          headers: {
            "Access-Control-Allow-Origin": "*"
          },
        })
        .then((response) => {
          setInfoMessage(response.data + "✅");
        })
        .catch((error) => {
          // Handle the error
          console.log(error);
          setInfoMessage(error);
        });
    }
  };

  const clearLocalStorage = () => {
    localStorage.removeItem("persist:leaderboard");
  };

  return (
    <>
      <div className='header-top header-top-unverified'>
        <div className='container-f'>
          <div className='left-header'>
            <a><img src={logo} alt="Tycoon Logo" height={42} /></a>
          </div>
        </div>
      </div>
      <br></br>
      <br></br>
      <div className="unverifiedPage" style={{ textAlign: '-webkit-center', padding: '20px' }}>
        <div className="main-profiles unverifiedBox">
          <div className="profile-box box-bdr-bg">
            <div className="text-white text-center mt-3">
              <h2>Your email is not verified yet</h2>
              <br></br>
              <span>Please check your email for a verification link and follow the instructions to verify your email address.</span>
              <p>Once you've verified your email, you'll be able to log in to your account. Thanks!</p>
              <br></br>
              <div>
                {" "}
                <input
                  type="button"
                  value={'Resend Email'}
                  className="btn btn-primary resendEmail Favorite"
                  onClick={() => resendEmail()}
                />
              </div>
              <br></br>
              <a href='#' onClick={() => { logout({ logoutParams: { returnTo: window.location.origin } }); clearLocalStorage(); }} className='text-white' style={{ textDecoration: 'none' }}> Logout</a>
              <div className="text-success text-center mt-2">{infoMessage}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}