import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import stylees from "../css/pricing.module.css";

export const Pricing = () => {
  const stripeConfig = window._env_.REACT_APP_STRIPE_CONFIG;
  const monthlyPlanPaymentLink = stripeConfig.monthlyPlanPaymentLink;
  const yearlyPlanPaymentLink = stripeConfig.yearlyPlanPaymentLink;
  const lifeTimePlanPaymentLink = stripeConfig.lifeTimePlanPaymentLink;
  const userRecord = useSelector(state => state.user);

  const [userId, setUserId] = useState("");
  const [userEmail, setUserEmail] = useState("");

  useEffect(() => {
    var userIdWithAutho = userRecord?.id;
    setUserId(userIdWithAutho?.replace('|', ''));
    setUserEmail(userRecord?.email);
  }, [userRecord])

  const redirectToPaymentLink = async (paymentLink) => {
    if (paymentLink && userId && userEmail) {
      const queryString = new URLSearchParams();
      queryString.append('client_reference_id', userId);
      queryString.append('prefilled_email', userEmail);

      const redirectUrl = `${paymentLink}?${queryString.toString()}`;

      try {
        window.location.href = redirectUrl;
      } catch (error) {
        console.error('Failed to redirect to payment link:', error);
      }
    } else {
      console.error('Missing required parameters');
    }
  };


  return (
    <>
      <div className="toppricing" style={{ width: '100%' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div className={`${stylees.pricingtop} row align-items-center`} style={{ width: '100%' }}>
            <h1 className="col-12 col-sm-10 col-sm-11 text-center text-sm-center">
              <div className="row">
                <div className="col-12">
                  <button type="button" style={{ backgroundColor: "#fff", height: '30px', width: '30px', position: 'relative', float: 'right', fontSize: 'initial', marginTop: '1%' }} class="btn-close crossBtn" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="col-12">
                  <span>ELEVATE YOUR TRADING</span>
                  <br /> TO THE NEXT LEVEL
                </div>
              </div>
            </h1>
            <h2 className={`text-center ${stylees.save40Tagline}`}>SAVE 40% BY CHOOSING THE YEARLY PLAN</h2>
            <h3>
              <div style={{ lineHeight: '1.45', paddingTop: '1%' }}>
                <span>Start for free now</span>
                <br />
                7 day risk-free trial, no credit card required
              </div>
            </h3>
          </div>

        </div>


        <div className={`row justify-content-space-evenly ${stylees.plancard} `}>
          <div className={`col-12 col-md-5 col-lg-5 col-xl-3 mb-4 ${stylees.plancardfree}`}>
            <h1 className="text-center">Monthly Premium <div className={stylees.pricingduration}>Billed Monthly</div></h1>
            <div className={stylees.plancardborderyellow}></div>
            <div className="d-flex align-items-center justify-content-start text-center">
              <h2>€79.00</h2>
              <div className={stylees.pricingduration}>Per Month</div>
            </div>
            <ul>
              <li className={stylees.greentick}>7-Days Free Trial</li>
              <li className={stylees.greentick}>Full Traders Leaderboard access</li>
              <li className={stylees.greentick}>Real-time Trader Positions</li>
              <li className={stylees.greentick}>Track your own trading performance</li>
              <li className={stylees.greentick}>Community access</li>
              <li className={stylees.greentick}>Fully detailed trading history</li>
              <li className={stylees.greentick}>Advanced statistics</li>
              <li className={stylees.greentick}>Real-time Telegram notifications</li>
              <li className={stylees.greentick}>Premium Support</li>
              <li className={stylees.greentick}>Cancel at any time</li>
            </ul>
            <button onClick={() => redirectToPaymentLink(monthlyPlanPaymentLink)}>UPGRADE NOW</button>
          </div>
          <div className={`col-12 col-md-5 col-lg-5 col-xl-3 mb-4 ${stylees.plancardfree}`}>
            <h1 className="text-center">Yearly Premium <div className={stylees.pricingduration}>Billed Yearly</div></h1>
            <div className={`${stylees.plancardborderyellow}`}></div>
            <div className="d-flex align-items-center justify-content-start">
              <h2>€49.00</h2>
              <div className={stylees.pricingduration}>Per Month</div>
            </div>
            <ul>
              <li className={stylees.greentick}>7-Days Free Trial</li>
              <li className={stylees.greentick}>Full Traders Leaderboard access</li>
              <li className={stylees.greentick}>Real-time Trader Positions</li>
              <li className={stylees.greentick}>Track your own trading performance</li>
              <li className={stylees.greentick}>Community access​</li>
              <li className={stylees.greentick}>Fully detailed trading history​</li>
              <li className={stylees.greentick}>Advanced statistics​</li>
              <li className={stylees.greentick}>Real-time Telegram notifications​</li>
              <li className={stylees.greentick}>Premium Support</li>
              <li className={stylees.greentick}>Cancel at any time</li>
            </ul>
            <button onClick={() => redirectToPaymentLink(yearlyPlanPaymentLink)}>UPGRADE NOW</button>
          </div>
          <div className={`col-12 col-md-5 col-lg-5 col-xl-3 mb-4 ${stylees.plancardfree}`}>
            <h1 className="text-center">Lifetime Membership <div className={stylees.pricingduration}>Billed one-time</div></h1>
            <div className={`${stylees.plancardborderyellow}`}></div>
            <div className="d-flex align-items-center justify-content-start">
              <h2>€1,190.00</h2>
              <div className={stylees.pricingduration}>Single payment</div>
            </div>
            <ul>
              <li className={stylees.greentick}><b> 7-Days Free Trial</b></li>
              <li className={stylees.greentick}>Full Traders Leaderboard access</li>
              <li className={stylees.greentick}>Real-time Trader Positions</li>
              <li className={stylees.greentick}>Track your own trading performance</li>
              <li className={stylees.greentick}>Community access</li>
              <li className={stylees.greentick}>Fully detailed trading history</li>
              <li className={stylees.greentick}>Advanced statistics</li>
              <li className={stylees.greentick}>Real-time Telegram notifications</li>
              <li className={stylees.greentick}>Premium Support</li>
              <li className={stylees.greentick}>Pay with Binance/Crypto</li>
            </ul>
            <button onClick={() => redirectToPaymentLink(lifeTimePlanPaymentLink)}>UPGRADE NOW</button>
          </div>
        </div>
      </div>
    </>
  );
};
