import MuiTooltip from '@mui/material/Tooltip';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';

const ResponsiveTooltip = ({ children, title }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [openDialog, setOpenDialog] = useState(false);

  const handleTooltipClick = () => {
    if (isMobile) {
      setOpenDialog(true);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  if (isMobile) {
    return (
      <>
        <span onClick={handleTooltipClick}>{children}</span>
        <Dialog
          onClose={handleCloseDialog}
          open={openDialog}
          PaperProps={{
            sx: {
              position: 'static',
              bgcolor: 'tonal_03',
              borderRadius: '12px',
              padding: '40px 12px',
              marginLeft: '8px',
              marginRight: '8px',
            },
          }}
        >
          <IconButton
            sx={{ position: 'absolute', top: '5px', right: '5px' }}
            onClick={handleCloseDialog}
          >
            <CloseIcon sx={{ color: 'common.white' }} />
          </IconButton>
          <DialogTitle color='common.white' typography='text_s' sx={{ p: 0 }}>
            {title}
          </DialogTitle>
        </Dialog>
      </>
    );
  }

  return <MuiTooltip title={title}>{children}</MuiTooltip>;
};

export default ResponsiveTooltip;
