import React from 'react';
import styles from "../css/setting.module.css";
import tele from "../Components/Images/icontele.svg";
import copyicon from "../Components/Images/copy.svg";

function ConnectTelegramModal({
    closeConnectTelegramModal,
    onTelegramLinkClicked,
    userInfo,
    botNameRefer,
    isBotNameCopied,
    copyTGBotnameToClipboard,
    refer,
    isSecretCopied,
    copyToClipboard,
}) {
    return (
        <>
            <div className={styles.overlay}></div>
            <div className={styles.modal}>
                <main className={styles.Telemodal__main}>
                    <h2>Connect your Telegram</h2>
                    <i
                        className="fa fa-times"
                        aria-hidden="true"
                        role="button"
                        onClick={closeConnectTelegramModal}
                        style={{ cursor: 'pointer' }}
                    ></i>
                    <p>Connect your TG account to Tycoon by clicking this Link:</p>
                    <button onClick={onTelegramLinkClicked}>
                        <img src={tele} alt="Telegram" /> {userInfo.telegramBotName}
                    </button>
                    <h3 style={{ color: "#fff" }}>OR</h3>
                    <h3 style={{ color: "#fff" }}>STEP 01</h3>
                    <p style={{ color: "#F3BA2F" }}>Open the Tycoon Signals Bot on TG</p>
                    <label style={{ display: "flex", flexWrap: "wrap" }}>
                        <input ref={botNameRefer} value={userInfo.telegramBotName} className="text-center" />
                        <img
                            onClick={copyTGBotnameToClipboard}
                            src={copyicon}
                            style={{ margin: "20px -31px" }}
                        />
                    </label>
                    <p>{isBotNameCopied ? 'Telegram bot name copied successfully' : ''}</p>
                    <h3 style={{ color: "#fff" }}>STEP 02</h3>
                    <p style={{ color: "#F3BA2F" }}>
                        Copy the text below and send it to the Bot to connect your account. That’s it!
                    </p>
                    <label style={{ display: "flex", flexWrap: "wrap" }}>
                        <input ref={refer} value={userInfo.telegramSecret} className="text-center" />
                        <img
                            onClick={copyToClipboard}
                            src={copyicon}
                            style={{ margin: "20px -31px" }}
                        />
                    </label>
                    <p>{isSecretCopied ? 'Secret copied successfully' : ''}</p>
                </main>
            </div>
        </>
    );
}

export default ConnectTelegramModal;
