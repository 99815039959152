import {
  UPDATE_PERIOD_TYPE, UPDATE_PROFILE_TYPE, UPDATE_SEARCH_NAME,
  UPDATE_SORT_DIRECTION, UPDATE_SORT_TYPE, UPDATE_STATS_TYPE,
  UPDATE_SUBSCRIPTION_FLAG, UPDATE_USER, UPDATE_CURRENT_PAGE,
  UPDATE_ROWS_PER_PAGE, UPDATE_TOTAL_ITEMS, UPDATE_SKIP_RECORDS,
  UPDATE_PREVIOUS_PROFILE_TYPE, UPDATE_ONLY_PROFITABLE_FILTER,
  UPDATE_TRACKING_DAYS_FILTER, UPDATE_PNL_HIGHER_THAN_FILTER,
  UPDATE_TRADES_LAST_SEVEN_DAYS_FILTER,
  UPDATE_WIN_RATE_FILTER, UPDATE_IS_FILTERS_ACTIVATED,
  UPDATE_RESET_CUSTOM_NUMBER_FLAG
} from "./leaderboardConstants";

export const leaderboardReducer = function (state = {
  selectedProfileType: 'Public',
  previousSelectedProfileType: 'Public',
  statsType: 'PNL',
  periodType: 'weekly', search: '',
  sortType: 'Weekly', sortDirection: 'desc',
  hasSubscription: null, user: null,
  totalItems: 0,
  currentPage: 1,
  rowsPerPage: 10,
  skipRecords: 0,
  onlyProfitableFilter: true, trackingDaysFilter: 0,
  pnlHigherThanFilter: 0, tradesLastSevenDaysFilter: 0,
  winRateFilter: 0, isFiltersActivated: false, resetCustomNumberFlag: false
}, action) {
  switch (action.type) {
    case UPDATE_PROFILE_TYPE:
      return { ...state, selectedProfileType: action.payload }
    case UPDATE_PREVIOUS_PROFILE_TYPE:
      return { ...state, previousSelectedProfileType: action.payload }
    case UPDATE_STATS_TYPE:
      return { ...state, statsType: action.payload }
    case UPDATE_PERIOD_TYPE:
      return { ...state, periodType: action.payload }
    case UPDATE_SORT_TYPE:
      return { ...state, sortType: action.payload }
    case UPDATE_SORT_DIRECTION:
      return { ...state, sortDirection: action.payload }
    case UPDATE_SEARCH_NAME:
      return { ...state, search: action.payload }
    case UPDATE_SUBSCRIPTION_FLAG:
      return { ...state, hasSubscription: action.payload }
    case UPDATE_USER:
      return { ...state, user: action.payload }
    case UPDATE_CURRENT_PAGE:
      return { ...state, currentPage: action.payload };
    case UPDATE_ROWS_PER_PAGE:
      return { ...state, rowsPerPage: action.payload };
    case UPDATE_TOTAL_ITEMS:
      return { ...state, totalItems: action.payload };
    case UPDATE_SKIP_RECORDS:
      return { ...state, skipRecords: action.payload };
    case UPDATE_ONLY_PROFITABLE_FILTER:
      return { ...state, onlyProfitableFilter: action.payload };
    case UPDATE_TRACKING_DAYS_FILTER:
      return { ...state, trackingDaysFilter: action.payload };
    case UPDATE_PNL_HIGHER_THAN_FILTER:
      return { ...state, pnlHigherThanFilter: action.payload };
    case UPDATE_TRADES_LAST_SEVEN_DAYS_FILTER:
      return { ...state, tradesLastSevenDaysFilter: action.payload };
    case UPDATE_WIN_RATE_FILTER:
      return { ...state, winRateFilter: action.payload };
    case UPDATE_IS_FILTERS_ACTIVATED:
      return { ...state, isFiltersActivated: action.payload };
    case UPDATE_RESET_CUSTOM_NUMBER_FLAG:
      return { ...state, resetCustomNumberFlag: action.payload };
    default:
      return state;
  }
};