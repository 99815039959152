import React, { PureComponent, useEffect, useState, useRef } from 'react';

import totalprofit from '../Components/Images/profit.svg';
import trader from '../Components/Images/Performance Icon.svg';
import followers from '../Components/Images/followers-on-binance.svg';
import Trading from '../Components/Images/money-bag.svg';
import Tracking from '../Components/Images/days.svg';

import Totaltrade from '../Components/Images/total-trades.svg';
import profitable from '../Components/Images/profits1.svg';
import singletrades from '../Components/Images/roi.svg';
import bestsingle from '../Components/Images/money-bagsn.svg';
import tradesper from '../Components/Images/barrel-per-day1.svg';
import winrate from '../Components/Images/trophy 1.svg';
import average from '../Components/Images/average.svg';
import tradeduration from '../Components/Images/history1.svg';
import biggestsingle from '../Components/Images/bankruptcy1.svg';
import styles from '../css/setting.module.css';
import vectorArrow from '../Components/Images/Vector.svg';
import vectorArrowUp from '../Components/Images/VectorUp.svg';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import Loader from './Loader';
import dummy from './Images/defaultProfile.png';
import { Pricing } from './Pricing';
import { InformationMessageModal } from './Common/InformationMessage';
import { useSortable } from './Common/UseSortable';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import TabList from '@mui/lab/TabList';
import { useDispatch, useSelector } from 'react-redux';
import { updateSubscriptionFlag } from '../Redux/leaderboardAction';
import TextContainer from './TextContainer';
import { PaginationRow } from './PaginationComponent';
import ConnectTelegramModal from './ConnectTelegramModal';
import NotSharingPositionIcon from './icons/NotSharingPositionIcon';
import { formatDateToText } from '../utils/formatters';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ResponsiveTooltip from './ResponsiveTooltip';
import { Stack, Tab, Typography, useMediaQuery } from '@mui/material';

export const User = (props) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [leaderRecord, setLeaderRecord] = useState({});
  const [leaderStatsRecord, setLeaderStatsRecord] = useState({});
  const [isLeaderLoading, setIsLeaderLoading] = useState(true);
  const [isPerformanceLoading, setIsPerformanceLoading] = useState(true);
  const [isPositionHistoryLoading, setIsPositionHistoryLoading] =
    useState(true);
  const [isTradeHistoryLoading, setIsTradeHistoryLoading] = useState(true);
  const [isStatisticsLoading, setIsStatisticsLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [favoriteVal, setFavoriteVal] = useState('Mark as Favorite');
  const [followerVal, setFollowerVal] = useState('Follow');
  const [errorMsg, setErrorMsg] = useState('');
  const [hasSubscription, setHasSubscription] = useState(null);
  const leaderId = props.leaderId;
  const [userId, setUserId] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [show, setShow] = useState(false);
  const showStripeModal = () => setShow(true);
  const [statisticType, setStatisticType] = useState('PNL');
  const [performanceData, setPerformanceData] = useState();
  const [sortPositionConfiguration, setPositionHistorySortConfiguration] =
    useState({});
  const [sortTradeConfiguration, setTradeHistorySortConfiguration] = useState(
    {}
  );

  // op pagination

  const [opdata, setopdata] = useState(null);
  const {
    items: itemsOP,
    requestSort: requestSortOP,
    sortConfig: sortConfigOP,
  } = useSortable(opdata);
  const [currentPageOP, setcurrentPageOP] = useState(1);
  const [recordsPerPageOP, setRecordsPerPageOP] = useState(10);
  const indexOfLastRecordOP = currentPageOP * recordsPerPageOP;
  const indexOfFirstRecordOP = indexOfLastRecordOP - recordsPerPageOP;
  const currentRecordsOP =
    itemsOP != null
      ? itemsOP.slice(indexOfFirstRecordOP, indexOfLastRecordOP)
      : [];
  const [totalRecordsOP, setTotalRecordsOP] = useState(0);
  const [nPagesOP, setnPagesOP] = useState(1);

  const [positionHistorydata, setPositionHistorydata] = useState(null);
  const [tradeHistorydata, setTradeHistorydata] = useState(null);
  const [currentPagePH, setcurrentPagePH] = useState(1);
  const [currentPageTH, setcurrentPageTH] = useState(1);
  const [recordsPerPagePH, setRecordsPerPagePH] = useState(10);
  const [recordsPerPageTH, setRecordsPerPageTH] = useState(10);
  const pageOfLastRecordPH = currentPagePH * recordsPerPagePH;
  const pageFirstRecordPH = pageOfLastRecordPH - recordsPerPagePH + 1;
  const [totalRecordsPH, setTotalRecordsPH] = useState(0);
  const [totalRecordsTH, setTotalRecordsTH] = useState(0);
  const [nPagesPH, setnPagesPH] = useState(1);
  const [nPagesTH, setnPagesTH] = useState(1);
  const [userInfo, setUserInfo] = useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const closeInfoModal = () => setIsInfoModalOpen(false);
  const OpenInfoModal = () => setIsInfoModalOpen(true);
  const {
    items: itemsPH,
    requestSort: requestSortPH,
    sortConfig: sortConfigPH,
  } = useSortable(positionHistorydata);
  const {
    items: itemsTH,
    requestSort: requestSortTH,
    sortConfig: sortConfigTH,
  } = useSortable(tradeHistorydata);
  const subscriptionFlag = useSelector((state) => state.hasSubscription);
  const dispatch = useDispatch();
  const [hasFreeSubscription, setHasFreeSubscription] = useState(false);
  const [isFreeSubscriptionExpired, setIsFreeSubscriptionExpired] =
    useState(false);
  const [hasTrialSubscription, setHasTrialSubscription] = useState(false);

  const freeSubscriptionConfig =
    window._env_.REACT_APP_FREE_SUBSCRIPTION_OPTIONS;
  const freeSubscriptionEnabled =
    freeSubscriptionConfig.freeSubscriptionEnabled;
  const freeSubscriptionStart = new Date(
    freeSubscriptionConfig.freeSubscriptionStart
  );
  const freeSubscriptionEnd = new Date(
    freeSubscriptionConfig.freeSubscriptionEnd
  );
  const userRecord = useSelector((state) => state.user);

  const [isConnectTelegramModalOpen, setIsConnectTelegramModalOpen] =
    useState(false);
  const [isSecretCopied, setIsSecretCopied] = useState(false);
  const [isBotNameCopied, setIsBotNameCopied] = useState(false);

  const [isNotificationShow, setIsNotificationShow] = useState(true);

  const [activeHistoryTable, setActiveHistoryTable] = useState('position');

  const [expandedTradeRow, setExpandedTradeRow] = useState(null);

  const telegramBotLink = window._env_.REACT_APP_TELEGRAM_BOT_LINK;

  const refer = useRef(null);
  const botNameRefer = useRef(null);

  const openConnectTelegramModal = () => {
    setIsSecretCopied();
    setIsBotNameCopied();
    setIsConnectTelegramModalOpen(true);
  };

  const closeConnectTelegramModal = () => {
    setIsConnectTelegramModalOpen(false);
  };

  const onTelegramLinkClicked = () => {
    let botLinkWithSecret =
      telegramBotLink + '?start=' + userInfo?.telegramSecret;
    window.open(botLinkWithSecret); //to open new page
  };

  const copyToClipboard = async (e) => {
    refer.current.select();
    await navigator.clipboard.writeText(refer.current.value);
    setIsSecretCopied(true);
  };

  const copyTGBotnameToClipboard = async (e) => {
    botNameRefer.current.select();
    await navigator.clipboard.writeText(botNameRefer.current.value);
    setIsBotNameCopied(true);
  };

  const updatePositionHistorySortConfiguration = (key) => {
    let direction = 'ascending';
    if (
      sortPositionConfiguration &&
      sortPositionConfiguration.key === key &&
      sortPositionConfiguration.direction === 'ascending'
    ) {
      direction = 'descending';
    }
    let config = { key, direction };
    setPositionHistorySortConfiguration(config);
    return config;
  };

  const updateTradeHistorySortConfiguration = (key) => {
    let direction = 'ascending';
    if (
      sortTradeConfiguration &&
      sortTradeConfiguration.key === key &&
      sortTradeConfiguration.direction === 'ascending'
    ) {
      direction = 'descending';
    }
    let config = { key, direction };
    setPositionHistorySortConfiguration(config);
    return config;
  };

  useEffect(() => {
    const currentDate = new Date();

    if (
      freeSubscriptionEnabled &&
      currentDate >= freeSubscriptionStart &&
      currentDate <= freeSubscriptionEnd
    ) {
      setIsFreeSubscriptionExpired(false);
    } else {
      setIsFreeSubscriptionExpired(true);
    }

    if (isAuthenticated) {
      sessionStorage.setItem('isAuthenticated', 'true');
    }
  }, []);

  const fetchSortedLeaderPositionHistoryData = async (key) => {
    let config = updatePositionHistorySortConfiguration(key);
    let direction = config.direction == 'descending' ? 'desc' : '';
    await fetchLeaderPositionHistoryData(
      currentPagePH,
      config.key,
      direction,
      recordsPerPagePH
    );
  };

  const fetchSortedLeaderTradeHistoryData = async (key) => {
    let config = updateTradeHistorySortConfiguration(key);
    let direction = config.direction == 'descending' ? 'desc' : '';
    await fetchLeaderTradeHistoryData(
      currentPageTH,
      config.key,
      direction,
      recordsPerPageTH
    );
  };

  const fetchLeaderPositionHistoryData = async (
    pageNo,
    sortByColumn,
    direction,
    recordsPerPage
  ) => {
    let takeRecords =
      recordsPerPage && recordsPerPage !== undefined
        ? recordsPerPage
        : recordsPerPagePH;
    const skipRecords = takeRecords * (pageNo - 1);
    setIsPositionHistoryLoading(true);
    const token = await getAccessTokenSilently();

    let url = `${window._env_.REACT_APP_API_URL}/LeaderboardApi/GetLeaderHistory?leaderId=${props.leaderId}&skipRecords=${skipRecords}&takeRecords=${takeRecords}`;

    if (sortByColumn) {
      url += `&sortingType=${sortByColumn}&direction=${direction}`;
    }

    axios
      .get(url, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.data && response.data.mergedPositionsData) {
          setnPagesPH(Math.ceil(response.data.records / recordsPerPagePH));
          setPositionHistorydata(response.data.mergedPositionsData);
          setTotalRecordsPH(response.data.records);
        }

        setIsPositionHistoryLoading(false);
      })
      .catch((error) => {
        // Handle the error
        console.log(error);
      });
  };

  const fetchLeaderTradeHistoryData = async (
    pageNo,
    sortByColumn,
    direction,
    recordsPerPage
  ) => {
    let takeRecords =
      recordsPerPage && recordsPerPage !== undefined
        ? recordsPerPage
        : recordsPerPagePH;
    const skipRecords = takeRecords * (pageNo - 1);
    setIsTradeHistoryLoading(true);
    const token = await getAccessTokenSilently();

    let url = `${window._env_.REACT_APP_API_URL}/LeaderboardApi/GetLeaderTradeHistory?leaderId=${props.leaderId}&skipRecords=${skipRecords}&takeRecords=${takeRecords}`;

    if (sortByColumn) {
      url += `&sortingType=${sortByColumn}&direction=${direction}`;
    }

    axios
      .get(url, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.data && response.data.groupedTradeHistoryData) {
          setnPagesTH(Math.ceil(response.data.records / recordsPerPageTH));
          setTradeHistorydata(response.data.groupedTradeHistoryData);
          setTotalRecordsTH(response.data.records);
        }

        setIsTradeHistoryLoading(false);
      })
      .catch((error) => {
        // Handle the error
        console.log(error);
      });
  };

  useEffect(() => {
    let trialSubscriptionCondition = false;
    // Get the current date
    const currentDate = new Date();
    const trialEndDate = new Date(userRecord?.trialPeriod?.trialEnd);
    if (currentDate <= trialEndDate) {
      trialSubscriptionCondition = true;
      setHasTrialSubscription(true);
    }

    userRecord &&
    (userRecord.subscription !== null ||
      userRecord.lifeTimePlanStripe ||
      trialSubscriptionCondition)
      ? setHasSubscription(true)
      : setHasSubscription(false);
    userRecord &&
    (userRecord.subscription === null || !userRecord.lifeTimePlanStripe) &&
    userRecord?.freeSubscription?.isFreeSubscription
      ? setHasFreeSubscription(true)
      : setHasFreeSubscription(false);
    dispatch(
      updateSubscriptionFlag(
        userRecord &&
          (userRecord.subscription !== null || userRecord.lifeTimePlanStripe)
          ? true
          : false
      )
    );
    setUserInfo(userRecord);

    // this is for add favorite
    const favoriteLeaderIds = userRecord?.favoriteLeaderIds || [];
    const favoriteLeaders = favoriteLeaderIds.includes(leaderId);
    setFavoriteVal(favoriteLeaders ? 'Remove Favorite' : 'Mark as Favorite');

    // this is for add follow
    const followerLeaderIds = userRecord?.followerLeaderIds || [];
    const followerLeaders = followerLeaderIds.includes(leaderId);
    setFollowerVal(followerLeaders ? 'Unfollow' : 'Follow');

    var userIdWithAutho = userRecord?.id;

    setUserId(userIdWithAutho?.replace('|', ''));

    setUserEmail(userRecord?.email);
  }, [userRecord]);

  useEffect(() => {
    const savedIsAuthenticated = sessionStorage.getItem('isAuthenticated');
    if (savedIsAuthenticated) {
      sessionStorage.setItem('isAuthenticated', 'true');

      const fetchLeaderData = async () => {
        const token = await getAccessTokenSilently();
        axios
          .get(
            `${window._env_.REACT_APP_API_URL}/LeaderboardApi/GetLeaderProfile?leaderId=${props.leaderId}`,
            {
              headers: {
                'Access-Control-Allow-Origin': '*',
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            setLeaderRecord(response.data);
            if (
              response.data &&
              response.data.leader &&
              response.data.leader.positions
            ) {
              setTotalRecordsOP(response.data.leader.positions.length);
              setnPagesOP(
                Math.ceil(
                  response.data.leader.positions.length / recordsPerPageOP
                )
              );
              setopdata(response.data.leader.positions);
            }

            setIsLeaderLoading(false);
          })
          .catch((error) => {
            // Handle the error
            console.log(error);
          });
      };
      const fetchLeaderStatData = async () => {
        setIsLoading(true);
        const token = await getAccessTokenSilently();
        axios
          .get(
            `${window._env_.REACT_APP_API_URL}/LeaderboardApi/GetLeaderStatistics?leaderId=${props.leaderId}`,
            {
              headers: {
                'Access-Control-Allow-Origin': '*',
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            setLeaderStatsRecord(response.data);
            setIsStatisticsLoading(false);
          })
          .catch((error) => {
            // Handle the error
            console.log(error);
          });
      };

      setIsLoading(true);
      // userApi();
      fetchLeaderData();
      fetchLeaderStatData();
    }
  }, [leaderId]);

  useEffect(() => {
    if (userId == null) return;

    if (
      hasSubscription ||
      (!isFreeSubscriptionExpired && hasFreeSubscription)
    ) {
      fetchLeaderPositionHistoryData(currentPagePH);
    }
  }, [leaderId, userId]);

  useEffect(() => {
    if (isAuthenticated) {
      const fetchLeaderPerformanceData = async () => {
        setIsPerformanceLoading(true);
        const token = await getAccessTokenSilently();
        axios
          .get(
            `${window._env_.REACT_APP_API_URL}/LeaderboardApi/GetLeaderPerformance?leaderId=${props.leaderId}&statisticType=${statisticType}`,
            {
              headers: {
                'Access-Control-Allow-Origin': '*',
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            setPerformanceData(response.data);
            setIsPerformanceLoading(false);
          })
          .catch((error) => {
            // Handle the error
            console.log(error);
          });
      };
      setIsLoading(true);
      fetchLeaderPerformanceData();
    }
  }, [statisticType]);

  class CustomizedAxisTick extends PureComponent {
    render() {
      const { x, y, stroke, payload } = this.props;
      return (
        <g transform={`translate(${x},${y})`}>
          <text
            x={0}
            y={0}
            dy={18}
            dx={18}
            textAnchor='end'
            fontSize={'12px'}
            fontWeight={400}
            fill='#fff'
          >
            {payload.value}
          </text>
        </g>
      );
    }
  }
  class CustomizedAxisTicks extends PureComponent {
    render() {
      const { x, y, stroke, payload } = this.props;

      return (
        <g transform={`translate(${x},${y})`}>
          <text
            x={0}
            y={0}
            dy={12}
            dx={20}
            textAnchor='end'
            fontSize={'12px'}
            fontWeight={400}
            fill='#fff'
          >
            {statisticType === 'ROI'
              ? `${payload.value.toLocaleString()}%`
              : `${payload.value.toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}`}
          </text>
        </g>
      );
    }
  }
  const data = performanceData !== undefined ? performanceData : null;

  const addFavoriteLeader = async (leaderId, isFavorite) => {
    if (isAuthenticated) {
      const token = await getAccessTokenSilently();
      axios
        .post(
          `${window._env_.REACT_APP_API_URL}/Users/AddFavoriteLeader?leaderId=${leaderId}&isFavorite=${isFavorite}`,
          {},
          {
            headers: {
              'Access-Control-Allow-Origin': '*',
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setIsLoading(false);
        })
        .catch((error) => {
          // Handle the error
          console.log(error);
        });
    }
  };

  const addFollowerLeader = async (leaderId, isFollower) => {
    if (isAuthenticated) {
      const token = await getAccessTokenSilently();
      axios
        .post(
          `${window._env_.REACT_APP_API_URL}/Users/AddFollowerLeader?leaderId=${leaderId}&isFollower=${isFollower}`,
          {},
          {
            headers: {
              'Access-Control-Allow-Origin': '*',
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setIsLoading(false);
        })
        .catch((error) => {
          // Handle the error
          console.log(error);
        });
    }
  };

  const toggleFavorite = async (e) => {
    if (favoriteVal === 'Remove Favorite') {
      setFavoriteVal('Mark as Favorite');
      setIsLoading(true);
      await addFavoriteLeader(leaderId, false);
    } else {
      setIsLoading(true);
      await addFavoriteLeader(leaderId, true);
      setFavoriteVal('Remove Favorite');
    }
  };
  const toggleFollower = async (e) => {
    if (followerVal === 'Unfollow') {
      setFollowerVal('Follow');
      setIsLoading(true);
      await addFollowerLeader(leaderId, false);
    } else {
      if (userInfo.followerLeaderIds.length >= 10) {
        OpenInfoModal();
        return;
      }
      if (hasSubscription) {
        setFollowerVal('Unfollow');
        setIsLoading(true);
        await addFollowerLeader(leaderId, true);
      } else if (!isFreeSubscriptionExpired && hasFreeSubscription) {
        setFollowerVal('Unfollow');
        setIsLoading(true);
        await addFollowerLeader(leaderId, true);
      } else {
        showStripeModal();
        // setErrorMsg("Upgrade for this feature")
      }
    }
  };

  const valueTypeFormatter = (value) => {
    if (statisticType === 'PNL') {
      return value.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      });
    } else {
      return `${value.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}%`;
    }
  };

  const validateUrl = (url) => {
    try {
      new URL(url);
      return true;
    } catch (error) {
      return false;
    }
  };

  const getClassNamesFor = (config, name) => {
    if (!config) {
      return;
    }
    return config.key === name ? config.direction : undefined;
  };

  const RedesignTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div
          className='custom-tooltip p-2 pb-0 pt-0'
          style={{
            backgroundColor: '#0e1a29',
            color: '#00BA80',
            border: '1px solid #01508C',
          }}
        >
          <p className='pt-2'>{label}</p>
          {statisticType === 'PNL'
            ? payload[0].value.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
              })
            : `${payload[0].value.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}%`}
        </div>
      );
    }
    return null;
  };

  const formatPrices = (number) => {
    const decimalPlaces = (number.toString().split('.')[1] || '').length;
    return decimalPlaces > 6 ? number.toFixed(6) : number.toString();
  };

  const handleStatisticType = (event, newValue) => {
    setStatisticType(newValue);
  };

  const HistoryHeader = ({ label, active, onClick }) => {
    return (
      <h3
        onClick={onClick}
        style={{
          cursor: 'pointer',
          display: 'inline-flex',
          marginRight: '20px',
          borderBottom: active ? '3px solid #2d9eff' : 'none',
          paddingBottom: active ? '6px' : '0',
          color: active ? '#2d9eff' : '',
        }}
      >
        {label}
      </h3>
    );
  };

  const TradeHistoryTabActive = () => {
    setActiveHistoryTable('trade');
    fetchLeaderTradeHistoryData(currentPageTH);
  };

  const handlePositionHistoryRowClick = (rowIndex) => {
    if (expandedTradeRow === rowIndex) {
      setExpandedTradeRow(null); // Collapse if clicked again
    } else {
      setExpandedTradeRow(rowIndex); // Expand the clicked row
    }
  };

  const calculateLeaderCreatedDateDifference = (createdAt) => {
    const now = new Date();
    const createdDate = new Date(createdAt);
    const difference = now.getTime() - createdDate.getTime();
    const daysDifference = Math.ceil(difference / (1000 * 3600 * 24));
    return daysDifference;
  };

  const notificationBarDismiss = () => {
    setIsNotificationShow(false);
  };

  return (
    <>
      <div className='container-f profile-design'>
        <div className='table_heading'>
          <div className='t-headin1'>
            <h3>Profile</h3>
            <div
              className={`loginbtn ${styles.settingback}`}
              style={{ position: 'relative', left: '-0%', top: '10px' }}
            >
              <ul>
                <li className={`loginbtn ${styles.backbutton}`}>
                  <Link to='/' style={{ width: '190%!important' }}>
                    {' '}
                    <span>
                      {' '}
                      <em>＜</em> Back to Leaderboard{' '}
                    </span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {leaderRecord &&
          leaderRecord.leader &&
          leaderRecord.leader.createdAt &&
          calculateLeaderCreatedDateDifference(leaderRecord.leader.createdAt) <
            7 &&
          isNotificationShow && (
            <>
              <div className='notification-bar p-2'>
                <div className='notification-content'>
                  <div className='notification-text'>
                    <span className='notification-icon'>
                      <svg
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <circle
                          cx='12'
                          cy='12'
                          r='11'
                          fill='white'
                          stroke='#1070CA'
                          strokeWidth='2'
                        />
                        <path
                          d='M12 9V10'
                          stroke='#1070CA'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                        <path
                          d='M12 12V16'
                          stroke='#1070CA'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                        <circle cx='12' cy='7' r='0.5' fill='#1070CA' />
                      </svg>
                    </span>
                    We recently added this trader to our Leaderboard. Charts and
                    statistics can look incomplete until we gather more data.
                  </div>
                  <div className='notification-dismiss'>
                    <button onClick={() => notificationBarDismiss()}>
                      <svg
                        width='20'
                        height='20'
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                        style={{ verticalAlign: 'sub' }}
                      >
                        <path
                          d='M18 6L6 18'
                          stroke='white'
                          stroke-width='2'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                        />
                        <path
                          d='M6 6L18 18'
                          stroke='white'
                          stroke-width='2'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                        />
                      </svg>
                      Dismiss
                    </button>
                  </div>
                </div>
              </div>
              <br />
            </>
          )}

        {isLeaderLoading ? (
          <Loader />
        ) : (
          <>
            {leaderRecord.leader && (
              <>
                <div className='common-profiles d-flex justify-content-between flex-wrap flex-xl-nowrap'>
                  <div className='main-profiles'>
                    <div className='profile-box box-bdr-bg'>
                      <div className='profile-imgs'>
                        <div className='profiles-badge'>
                          {' '}
                          {leaderRecord.leader.userPhotoUrl !== '' &&
                          validateUrl(leaderRecord.leader.userPhotoUrl) ? (
                            <img
                              src={leaderRecord.leader.userPhotoUrl}
                              alt='Profile image'
                            />
                          ) : (
                            <img src={dummy} alt='' className='trader_img' />
                          )}{' '}
                        </div>
                      </div>

                      <h4>{leaderRecord.leader.nickName}</h4>
                    </div>

                    <div className='leaderBio text-white'>
                      {/* <p>{leaderRecord.leader.introduction}</p> */}
                      <TextContainer text={leaderRecord.leader.introduction} />
                    </div>

                    <div className='body_follow '>
                      <div>
                        {' '}
                        {!isFreeSubscriptionExpired ? (
                          <>
                            {!hasFreeSubscription && !hasSubscription ? (
                              <input
                                type='button'
                                value={
                                  followerVal === 'Follow'
                                    ? '+ Follow'
                                    : '- Unfollow'
                                }
                                className='btn btn-primary follow'
                                data-bs-toggle='modal'
                                data-bs-target='#exampleModal'
                              />
                            ) : (
                              <>
                                {!userInfo?.telegramChatId ? (
                                  <>
                                    <input
                                      type='button'
                                      value={
                                        followerVal === 'Follow'
                                          ? '+ Follow'
                                          : '- Unfollow'
                                      }
                                      className='btn btn-primary follow'
                                      onClick={() => openConnectTelegramModal()}
                                    />
                                  </>
                                ) : (
                                  <>
                                    <input
                                      type='button'
                                      value={
                                        followerVal === 'Follow'
                                          ? '+ Follow'
                                          : '- Unfollow'
                                      }
                                      className='btn btn-primary follow'
                                      onClick={(e) => toggleFollower(e)}
                                    />
                                  </>
                                )}
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            {!hasSubscription ? (
                              <input
                                type='button'
                                value={
                                  followerVal === 'Follow'
                                    ? '+ Follow'
                                    : '- Unfollow'
                                }
                                className='btn btn-primary follow'
                                data-bs-toggle='modal'
                                data-bs-target='#exampleModal'
                              />
                            ) : (
                              <>
                                {!userInfo?.telegramChatId ? (
                                  <>
                                    <input
                                      type='button'
                                      value={
                                        followerVal === 'Follow'
                                          ? '+ Follow'
                                          : '- Unfollow'
                                      }
                                      className='btn btn-primary follow'
                                      onClick={() => openConnectTelegramModal()}
                                    />
                                  </>
                                ) : (
                                  <>
                                    <input
                                      type='button'
                                      value={
                                        followerVal === 'Follow'
                                          ? '+ Follow'
                                          : '- Unfollow'
                                      }
                                      className='btn btn-primary follow'
                                      onClick={(e) => toggleFollower(e)}
                                    />
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )}
                        <p className='text-danger'>{errorMsg}</p>
                      </div>
                      <div>
                        {' '}
                        <input
                          type='button'
                          value={favoriteVal}
                          className='btn btn-primary Favorite'
                          onClick={(e) => toggleFavorite(e)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className='number-box'>
                    <div className='icon-box-main'>
                      <div className='icon-boxs'>
                        <div>
                          {' '}
                          <img src={totalprofit} alt='Total Profit' />{' '}
                        </div>
                        <h3 className='phproi'>
                          {leaderRecord.tradesLastSevenDays
                            ? leaderRecord.tradesLastSevenDays
                            : 0}
                        </h3>
                        <h4> Trades past 7 days</h4>
                      </div>
                      
                      <div className='icon-boxs'>
                      <img src={followers} alt='Followers on Binance' />
                          <h3>{leaderRecord.leader?.followerCount} </h3>
                          <h4>
                            {' '}
                            {leaderRecord.leader?.followerCount === 1
                              ? 'Follower'
                              : 'Followers'}{' '}
                              on Binance
                          </h4>
                      </div>
                      
                        
                      <div className='icon-boxs'>
                        <div>
                          {' '}
                          <img src={Trading} alt='Total Trading' />{' '}
                        </div>
                        <h3
                          className={
                            leaderRecord.totalTradingVolume < 0
                              ? 'phnroi'
                              : 'phproi'
                          }
                        >
                          {leaderRecord.totalTradingVolume
                            ? Number(
                                leaderRecord.totalTradingVolume
                              ).toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                            : 0}{' '}
                        </h3>
                        <h4> Total Trading Volume </h4>
                      </div>
                      <div className='icon-boxs'>
                        <div>
                          {' '}
                          <img src={Tracking} alt='Tracking' />{' '}
                        </div>
                        <h3> {leaderRecord.trackingDays} days </h3>
                        <h4> of Tracking on Tycoon</h4>
                      </div>
                    </div>

                    <div className='trader-rank row justify-content-around gx-0'>
                      <div className='ranks col-xs-3 col-sm-12 col-lg-2'>
                        <div className='icon-rank'>
                          <img src={trader} alt='Followers on Binance' />

                          <h3>Performance</h3>
                        </div>
                      </div>

                      <div className='schedule-ranks col-1 col-sm-2 col-lg-2'>
                        <p className='schedule-ranksfirst'> Yesterday </p>
                        <h4
                          className={
                            leaderRecord.traderRanking.dailyROI < 0
                              ? 'color_red '
                              : 'color_green'
                          }
                        >
                          {leaderRecord.traderRanking.dailyROI < 0 ? '' : '+'}
                          {new Intl.NumberFormat('en-US', {
                            maximumFractionDigits: 2,
                          }).format(leaderRecord.traderRanking.dailyROI)}
                          %
                        </h4>
                        <p>
                          ≈ {leaderRecord.traderRanking.dailyPNL < 0 ? '' : '$'}
                          {new Intl.NumberFormat('en-US', {
                            maximumFractionDigits: 2,
                          })
                            .format(leaderRecord.traderRanking.dailyPNL)
                            .replace(/\-/g, '-$')}
                        </p>
                      </div>

                      <div className='schedule-ranks col-1 col-sm-2 col-lg-2'>
                        <p> This week </p>
                        <h4
                          className={
                            leaderRecord.traderRanking.weeklyROI < 0
                              ? 'color_red '
                              : 'color_green'
                          }
                        >
                          {leaderRecord.traderRanking.weeklyROI < 0 ? '' : '+'}
                          {new Intl.NumberFormat('en-US', {
                            maximumFractionDigits: 2,
                          }).format(leaderRecord.traderRanking.weeklyROI)}
                          %
                        </h4>
                        <p>
                          ≈{' '}
                          {leaderRecord.traderRanking.weeklyPNL < 0 ? '' : '$'}
                          {new Intl.NumberFormat('en-US', {
                            maximumFractionDigits: 2,
                          })
                            .format(leaderRecord.traderRanking.weeklyPNL)
                            .replace(/\-/g, '-$')}
                        </p>
                      </div>
                      <div className='schedule-ranks col-1 col-sm-2 col-lg-2'>
                        <p> This month </p>
                        <h4
                          className={
                            leaderRecord.traderRanking.monthlyROI < 0
                              ? 'color_red '
                              : 'color_green'
                          }
                        >
                          {leaderRecord.traderRanking.monthlyROI < 0 ? '' : '+'}
                          {new Intl.NumberFormat('en-US', {
                            maximumFractionDigits: 2,
                          }).format(leaderRecord.traderRanking.monthlyROI)}
                          %
                        </h4>
                        <p>
                          ≈{' '}
                          {leaderRecord.traderRanking.monthlyPNL < 0 ? '' : '$'}
                          {new Intl.NumberFormat('en-US', {
                            maximumFractionDigits: 2,
                          })
                            .format(leaderRecord.traderRanking.monthlyPNL)
                            .replace(/\-/g, '-$')}
                        </p>
                      </div>
                      <div className='schedule-ranks col-1 col-sm-2 col-lg-2'>
                        <p> All-time </p>
                        <h4
                          className={
                            leaderRecord.traderRanking.allROI < 0
                              ? 'color_red '
                              : 'color_green'
                          }
                        >
                          {leaderRecord.traderRanking.allROI < 0 ? '' : '+'}
                          {new Intl.NumberFormat('en-US', {
                            maximumFractionDigits: 2,
                          }).format(leaderRecord.traderRanking.allROI)}
                          %
                        </h4>
                        <p>
                          ≈ {leaderRecord.traderRanking.allPNL < 0 ? '' : '$'}
                          {new Intl.NumberFormat('en-US', {
                            maximumFractionDigits: 2,
                          })
                            .format(leaderRecord.traderRanking.allPNL)
                            .replace(/\-/g, '-$')}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}

            {/********************************* openposition *********************************/}
            <div className='statistics-main mainopen'>
              <Stack
                direction={{ xs: 'column', sm: 'row' }}
                alignItems={{ xs: 'left', sm: 'center' }}
                gap={{ xs: 1.25, sm: 2.5 }}
                p={{ xs: 3, md: 0 }}
                mb={{ sm: 5 }}
              >
                <Typography
                  variant={isMobile ? 'text_m_bold' : 'text_l_bold'}
                  component='h3'
                >
                  Open Positions
                </Typography>
                {leaderRecord.leader.positionShared === false &&
                  leaderRecord.leader?.positionSharedStopTime && (
                    <Stack
                      direction='row'
                      alignItems={{ xs: 'flex-start', sm: 'center' }}
                      gap={0.75}
                    >
                      <NotSharingPositionIcon fontSize='small' />
                      <Typography
                        textAlign='left'
                        variant='text_s'
                        color='error'
                        mr={{ xs: 0.75, md: 0 }}
                      >
                        Not sharing - This trader stopped sharing his positions
                        on{' '}
                        {formatDateToText(
                          leaderRecord.leader.positionSharedStopTime
                        )}
                      </Typography>
                      <ResponsiveTooltip
                        title='This is the last known positions of this trader before he stopped sharing his positions. 
We do NOT know if these trades are still open or not. As soon as the trader starts sharing again, his positions will update as usual.'
                      >
                        <InfoOutlinedIcon
                          fontSize='small'
                          sx={(theme) => ({
                            color: 'common.white',
                            cursor: 'pointer',
                            [theme.breakpoints.down('sm')]: {
                              color: 'error.main',
                            },
                          })}
                        />
                      </ResponsiveTooltip>
                    </Stack>
                  )}
              </Stack>

              <div className='table-main'>
                <div className='table-fn'>
                  <table className='table-user'>
                    <tr>
                      <th
                        onClick={() => requestSortOP('symbol')}
                        className={getClassNamesFor(sortConfigOP, 'symbol')}
                      >
                        Symbol
                      </th>
                      <th
                        onClick={() => requestSortOP('amount')}
                        className={getClassNamesFor(sortConfigOP, 'amount')}
                      >
                        Size
                      </th>
                      <th
                        onClick={() => requestSortOP('entryPrice')}
                        className={getClassNamesFor(sortConfigOP, 'entryPrice')}
                      >
                        {' '}
                        Entry Price{' '}
                      </th>
                      <th
                        onClick={() => requestSortOP('markPrice')}
                        className={getClassNamesFor(sortConfigOP, 'markPrice')}
                      >
                        {' '}
                        Mark Price{' '}
                      </th>
                      <th
                        onClick={() => requestSortOP('updateTimeStamp')}
                        className={getClassNamesFor(
                          sortConfigOP,
                          'updateTimeStamp'
                        )}
                      >
                        Time
                      </th>
                      <th
                        onClick={() => requestSortOP('roe')}
                        className={getClassNamesFor(sortConfigOP, 'roe')}
                      >
                        ROI
                      </th>
                      <th
                        onClick={() => requestSortOP('pnl')}
                        className={getClassNamesFor(sortConfigOP, 'pnl')}
                      >
                        PNL
                      </th>
                    </tr>
                    {currentRecordsOP &&
                      currentRecordsOP.map((object) => (
                        <tr className='openposition'>
                          <td className='opsymbol'>
                            {object.symbol} Perpetual
                            <br />
                            {object.amount > 0 ? (
                              <span className='phproi'>Long</span>
                            ) : (
                              <span className='phnroi'>Short</span>
                            )}
                            |<span>{object.leverage}x</span>
                          </td>
                          <td>{Math.abs(object.amount)}</td>
                          <td>{formatPrices(object.entryPrice)}</td>
                          <td>{formatPrices(object.markPrice)}</td>
                          <td>
                            {new Date(object.updateTimeStamp).toLocaleString()}
                          </td>
                          <td
                            style={{ whiteSpace: 'nowrap' }}
                            className={object.roe < 0 ? 'phnroi' : 'phproi'}
                          >
                            {' '}
                            {(object.roe * 100).toFixed(2)}%{' '}
                          </td>
                          <td
                            style={{ whiteSpace: 'nowrap' }}
                            className={object.pnl < 0 ? 'phnroi' : 'phproi'}
                          >
                            {' '}
                            {object.pnl.toLocaleString('en-US', {
                              style: 'currency',
                              currency: 'USD',
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}{' '}
                          </td>
                        </tr>
                      ))}
                  </table>
                </div>
                <div className='showing-c'>
                  <p
                    style={{
                      justifyContent: 'end',
                      alignItems: 'center',
                      padding: '5px',
                    }}
                  ></p>
                </div>
                <PaginationRow
                  currentPage={currentPageOP}
                  rowsPerPage={recordsPerPageOP}
                  totalItems={totalRecordsOP}
                  onPageChange={(newPage) => {
                    setcurrentPageOP(newPage);
                  }}
                  onRowsPerPageChange={(e) => {
                    const newRowsPerPage = Number(e.target.value);
                    setRecordsPerPageOP(newRowsPerPage);
                    setcurrentPageOP(1);
                  }}
                />
              </div>
            </div>
          </>
        )}
        {/********************************* openposition *********************************/}

        {/********************************* Graph *********************************/}
        <div className='graph-main'>
          <div className='proformance-colms d-flex justify-content-between  align-items-baseline'>
            <h3> Performance </h3>
            <div className='table_top_a setting_tab'>
              <TabContext value={statisticType}>
                <TabList
                  onChange={handleStatisticType}
                  aria-label='lab API tabs example'
                >
                  <Tab label='% ROI' value='ROI' />
                  <Tab label='$ PNL' value='PNL' />
                </TabList>
                <TabPanel value='ROI' sx={{ mt: '10px' }}></TabPanel>
                <TabPanel value='PNL' sx={{ mt: '10px' }}></TabPanel>
              </TabContext>
            </div>
          </div>
          {isPerformanceLoading ? (
            <Loader />
          ) : (
            <>
              <div className='graphtop'>
                <ResponsiveContainer height={400}>
                  <LineChart
                    width={1302}
                    height={400}
                    data={data}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray='0 0 0' stroke='#8090a31c' />

                    <XAxis
                      dataKey='name'
                      tick={<CustomizedAxisTick />}
                      // padding={{ left: 30 }}
                      padding={{ left: 30 }}
                      interval='preserveStartEnd'
                      tickLine={false}
                      axisLine={false}
                    />
                    <YAxis
                      strokeOpacity={0}
                      tickMargin={20}
                      padding={{ bottom: 30 }}
                      tickLine={false}
                      axisLine={false}
                      tick={<CustomizedAxisTicks />}
                      //domain={['dataMin', 'dataMax']}
                      domain={['auto', 'auto']}
                      tickFormatter={(tick) => {
                        return `${tick}%`;
                      }}
                    />
                    <Tooltip
                      viewBox={{ x: 0, y: 0, width: 800, height: 400 }}
                      contentStyle={{
                        backgroundColor: '#0e1a29',
                        color: '#00BA80',
                      }}
                      itemStyle={{
                        border: '1px solid white',
                        borderBlockColor: 'black',
                      }}
                      cursorStyle={{ borderColor: 'transparent' }}
                      cursor={{ stroke: '#01508C', strokeWidth: 2 }}
                      formatter={(value) => valueTypeFormatter(value)}
                      content={<RedesignTooltip />}
                    />
                    <Legend />
                    <Line
                      type='linear'
                      dataKey='value'
                      stroke='#00BA80'
                      dot={false}
                      activeDot={{ fill: '#041624', strokeWidth: 1, r: 8 }}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </>
          )}
        </div>
        {/********************************* Graph *********************************/}

        {/********************************* Statistics *********************************/}
        <div className='statistics-main'>
          <Typography
            variant={isMobile ? 'text_m_bold' : 'text_l_bold'}
            component='h3'
            p={{ xs: 3, md: 0 }}
            mb={{ xs: 2.5, md: 5 }}
          >
            Statistics
          </Typography>
          {isStatisticsLoading ? (
            <Loader />
          ) : (
            <>
              <div className='statistics-colm-main row justify-content-md-between justify-content-sm-between justify-content-lg-start  justify-content-evenly'>
                <div className='statistics-con-box mb-3 text-start p-3 col-lg-2 col-md-3 col-sm-5 col-5'>
                  <img src={Totaltrade} alt='Total Trades' />
                  <h4>{leaderStatsRecord.totalTrades} </h4>
                  <p> Trades </p>
                </div>

                <div className='statistics-con-box mb-3 text-start p-3 col-lg-2 col-md-3 col-sm-5 col-5'>
                  <img src={profitable} alt='Profitable Days' />
                  <h4 className='profit-c'>
                    {leaderStatsRecord.profitableDays}{' '}
                  </h4>
                  <p> Profitable Days </p>
                </div>

                <div className='statistics-con-box mb-3 text-start p-3 col-lg-2 col-md-3 col-sm-5 col-5'>
                  <img src={singletrades} alt='Best Single Trade ROI' />
                  <h4 className='profit-c'>
                    {leaderStatsRecord.highestTradeROI === null
                      ? 'N/A'
                      : leaderStatsRecord.highestTradeROI + '%'}{' '}
                  </h4>
                  <p> Best Single Trade ROI </p>
                </div>

                <div className='statistics-con-box mb-3 text-start p-3 col-lg-2 col-md-3 col-sm-5 col-5'>
                  <img src={bestsingle} alt='Best Single Trade Profit' />
                  <h4 className='profit-c'>
                    {leaderStatsRecord.highestTradePNL === null
                      ? '$0'
                      : parseFloat(
                          leaderStatsRecord.highestTradePNL
                        ).toLocaleString('en-US', {
                          style: 'currency',
                          currency: 'USD',
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}{' '}
                  </h4>
                  <p> Best Single Trade Profit </p>
                </div>

                <div className='statistics-con-box mb-3 text-start p-3 col-lg-2 col-md-3 col-sm-5 col-5'>
                  <img src={tradesper} alt='Trades per day ' />
                  <h4>
                    {leaderStatsRecord.tradesPerDay > 0
                      ? leaderStatsRecord.tradesPerDay
                      : '0'}{' '}
                  </h4>
                  <p> Trades per day </p>
                </div>

                <div className='statistics-con-box mb-3 text-start p-3 col-lg-2 col-md-3 col-sm-5 col-5'>
                  <img src={winrate} alt='Win Rate' />
                  <h4 className='profit-c'>
                    {leaderStatsRecord.winRatio === null
                      ? 'N/A'
                      : leaderStatsRecord.winRatio + '%'}{' '}
                  </h4>
                  <p> Win Rate </p>
                </div>

                <div className='statistics-con-box mb-3 text-start p-3 col-lg-2 col-md-3 col-sm-5 col-5'>
                  <img src={average} alt='Average Position Size' />
                  <h4 className='blue'>
                    {leaderStatsRecord.avgTradingSize === null
                      ? '$0'
                      : parseFloat(
                          leaderStatsRecord.avgTradingSize
                        ).toLocaleString('en-US', {
                          style: 'currency',
                          currency: 'USD',
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}{' '}
                  </h4>
                  <p> Average Position Size </p>
                </div>

                <div className='statistics-con-box mb-3 text-start p-3 col-lg-2 col-md-3 col-sm-5 col-5'>
                  <img src={tradeduration} alt='Average Trade Duration' />
                  <h4>
                    {leaderStatsRecord.avgTradeDuration === null
                      ? 'N/A'
                      : parseFloat(leaderStatsRecord.avgTradeDuration).toFixed(
                          0
                        ) === '1'
                      ? '1 Day'
                      : parseFloat(leaderStatsRecord.avgTradeDuration) < 1
                      ? (
                          parseFloat(leaderStatsRecord.avgTradeDuration) * 24
                        ).toFixed(0) + ' Hours'
                      : parseFloat(leaderStatsRecord.avgTradeDuration).toFixed(
                          0
                        ) + ' Days'}
                  </h4>
                  <p> Average Trade Duration </p>
                </div>

                <div className='statistics-con-box mb-3 text-start p-3 col-lg-2 col-md-3 col-sm-5 col-5'>
                  <img src={biggestsingle} alt='Biggest Single Trade Loss' />
                  <h4 className='red'>
                    {leaderStatsRecord.biggestTradeLoss === null
                      ? '$0'
                      : parseFloat(
                          leaderStatsRecord.biggestTradeLoss
                        ).toLocaleString('en-US', {
                          style: 'currency',
                          currency: 'USD',
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}{' '}
                  </h4>
                  <p> Biggest Single Trade Loss </p>
                </div>

                
              </div>
            </>
          )}
        </div>
        {/********************************* Statistics *********************************/}

        <div className='chart-container'>
          {!isFreeSubscriptionExpired ? (
            <>
              {!hasSubscription &&
                hasSubscription !== null &&
                !hasFreeSubscription && (
                  <>
                    <div
                      className={`${!hasSubscription ? 'chart-overlay' : ''}`}
                      style={{ userSelect: 'none' }}
                    >
                      <div
                        className={`${
                          !hasSubscription ? 'overlay-content' : 'd-none'
                        }`}
                      >
                        {/* <p>To see advanced statistics, performance charts, trading history and receive real-time notifications, you need to upgrade to Tycoon premium Membership.</p> */}
                        <p>
                          To see advanced trading history and receive real-time
                          notifications, you need to upgrade to Tycoon premium
                          Membership.
                        </p>
                        <Button
                          variant='warning'
                          data-bs-toggle='modal'
                          data-bs-target='#exampleModal'
                        >
                          Upgrade Now
                        </Button>
                      </div>
                      {/********************************* Positions History *********************************/}
                      <div className='positions-historys'>
                        <h3>Positions History </h3>

                        <div className='table-main'>
                          <div className='table-fn'>
                            <table className='table-user'>
                              <tr>
                                <th>Symbol</th>
                                <th>Size</th>
                                <th> Entry Price </th>
                                <th>Open Time</th>
                                <th>Close Time</th>
                                <th>PNL</th>
                                <th>Duration</th>
                              </tr>
                              <tr>
                                <td className='phsymbol'>
                                  <p>Etherum Perpetual</p>
                                  <span className='phnroi'>Long</span>|
                                  <span className='phyellow'>10x</span>
                                </td>
                                <td>2.00</td>
                                <td>2.00</td>
                                <td>12-12-2012</td>
                                <td>12-12-2012</td>
                                <td className='phnroi'> $2 </td>
                                <td>2 Days</td>
                              </tr>
                            </table>
                          </div>
                          <div className='showing-c'>
                            <p>
                              {' '}
                              Showing 1-03 of 1523 <span> ＜ ＞ </span>{' '}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              {((hasSubscription && hasSubscription !== null) ||
                hasFreeSubscription) && (
                <div>
                  {/********************************* Positions History *********************************/}
                  <div className='positions-historys'>
                    <div style={{ textAlign: 'left' }}>
                      <HistoryHeader
                        label='Positions History'
                        active={activeHistoryTable === 'position'}
                        onClick={() => setActiveHistoryTable('position')}
                      />
                      <HistoryHeader
                        label='Trades History'
                        active={activeHistoryTable === 'trade'}
                        onClick={() => TradeHistoryTabActive()}
                      />
                    </div>

                    {activeHistoryTable === 'position' && (
                      <>
                        {isPositionHistoryLoading ? (
                          <Loader />
                        ) : (
                          <>
                            <div className='table-main'>
                              <div className='table-fn'>
                                <table className='table-user'>
                                  <tr>
                                    <th>
                                      <button
                                        type='button'
                                        onClick={() => {
                                          requestSortPH('symbol');
                                          fetchSortedLeaderPositionHistoryData(
                                            'Symbol'
                                          );
                                        }}
                                        className={getClassNamesFor(
                                          sortConfigPH,
                                          'symbol'
                                        )}
                                      >
                                        Symbol
                                      </button>
                                    </th>
                                    <th>
                                      <button
                                        type='button'
                                        onClick={() => {
                                          requestSortPH('amount');
                                          fetchSortedLeaderPositionHistoryData(
                                            'Amount'
                                          );
                                        }}
                                        className={getClassNamesFor(
                                          sortConfigPH,
                                          'amount'
                                        )}
                                      >
                                        Size
                                      </button>
                                    </th>
                                    <th>
                                      <button
                                        type='button'
                                        onClick={() => {
                                          requestSortPH('entryPrice');
                                          fetchSortedLeaderPositionHistoryData(
                                            'EntryPrice'
                                          );
                                        }}
                                        className={getClassNamesFor(
                                          sortConfigPH,
                                          'entryPrice'
                                        )}
                                      >
                                        Entry Price
                                      </button>
                                    </th>
                                    <th>
                                      <button
                                        type='button'
                                        onClick={() => {
                                          requestSortPH('closePrice');
                                          fetchSortedLeaderPositionHistoryData(
                                            'ClosePrice'
                                          );
                                        }}
                                        className={getClassNamesFor(
                                          sortConfigPH,
                                          'closePrice'
                                        )}
                                      >
                                        Close Price
                                      </button>
                                    </th>
                                    <th>
                                      <button
                                        type='button'
                                        onClick={() => {
                                          requestSortPH('openTime');
                                          fetchSortedLeaderPositionHistoryData(
                                            'OpenTime'
                                          );
                                        }}
                                        className={getClassNamesFor(
                                          sortConfigPH,
                                          'openTime'
                                        )}
                                      >
                                        Open Time
                                      </button>
                                    </th>
                                    <th>
                                      <button
                                        type='button'
                                        onClick={() => {
                                          requestSortPH('closeTime');
                                          fetchSortedLeaderPositionHistoryData(
                                            'CloseTime'
                                          );
                                        }}
                                        className={getClassNamesFor(
                                          sortConfigPH,
                                          'closeTime'
                                        )}
                                      >
                                        Close Time
                                      </button>
                                    </th>
                                    <th>
                                      <button
                                        type='button'
                                        onClick={() => {
                                          requestSortPH('pnl');
                                          fetchSortedLeaderPositionHistoryData(
                                            'Pnl'
                                          );
                                        }}
                                        className={getClassNamesFor(
                                          sortConfigPH,
                                          'pnl'
                                        )}
                                      >
                                        PNL
                                      </button>
                                    </th>
                                    <th>
                                      <button
                                        type='button'
                                        onClick={() => {
                                          requestSortPH('days');
                                          fetchSortedLeaderPositionHistoryData(
                                            'Days'
                                          );
                                        }}
                                        className={getClassNamesFor(
                                          sortConfigPH,
                                          'days'
                                        )}
                                      >
                                        Duration
                                      </button>
                                    </th>
                                  </tr>
                                  {positionHistorydata &&
                                    positionHistorydata.map((object, index) => (
                                      <>
                                        <tr
                                          onClick={() =>
                                            handlePositionHistoryRowClick(index)
                                          }
                                        >
                                          <td>
                                            <div
                                              className='row'
                                              style={{ paddingTop: '15px' }}
                                            >
                                              <div className='col-2'>
                                                <img
                                                  src={
                                                    expandedTradeRow === index
                                                      ? vectorArrowUp
                                                      : vectorArrow
                                                  }
                                                  alt='Expanded Arrow for Trade History'
                                                />
                                              </div>
                                              <div
                                                className='col-10'
                                                style={{ lineHeight: '8px' }}
                                              >
                                                <p>{object.symbol} Perpetual</p>
                                                {object.amount > 0 ? (
                                                  <span className='phproi pl-3'>
                                                    Long
                                                  </span>
                                                ) : (
                                                  <span className='phshort'>
                                                    Short
                                                  </span>
                                                )}
                                                |
                                                <span className='phyellow'>
                                                  {object.leverage}x
                                                </span>
                                              </div>
                                            </div>
                                          </td>
                                          <td>{Math.abs(object.amount)}</td>
                                          <td>
                                            {formatPrices(object.entryPrice)}
                                          </td>
                                          <td>
                                            {formatPrices(object.markPrice)}
                                          </td>
                                          <td>
                                            {new Date(
                                              object.openTime
                                            ).toLocaleString()}
                                          </td>
                                          <td>
                                            {new Date(
                                              object.closeTime
                                            ).toLocaleString()}
                                          </td>
                                          <td
                                            style={{ whiteSpace: 'nowrap' }}
                                            className={
                                              object.pnl < 0
                                                ? 'phnroi'
                                                : 'phproi'
                                            }
                                          >
                                            {' '}
                                            {object.pnl.toLocaleString(
                                              'en-US',
                                              {
                                                style: 'currency',
                                                currency: 'USD',
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                              }
                                            )}{' '}
                                          </td>
                                          <td>
                                            {object.days === 1
                                              ? '< 1 day'
                                              : `< ${object.days} days`}
                                          </td>
                                        </tr>
                                        {expandedTradeRow === index && (
                                          <tr>
                                            <td colSpan={9}>
                                              <table className='expandable-table'>
                                                <thead>
                                                  <tr>
                                                    <th
                                                      style={{ width: '20%' }}
                                                    >
                                                      Symbol
                                                    </th>
                                                    <th>Side</th>
                                                    <th>Size</th>
                                                    <th>Price</th>
                                                    <th
                                                      style={{ width: '15%' }}
                                                    >
                                                      Time
                                                    </th>
                                                    <th>PNL</th>
                                                    <th>Duration</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {object.tradeHistoryList &&
                                                    object.tradeHistoryList.map(
                                                      (tradeHistoryObj) => (
                                                        <tr>
                                                          <td className='phsymbol'>
                                                            <p>
                                                              {
                                                                tradeHistoryObj.symbol
                                                              }{' '}
                                                              Perpetual
                                                            </p>
                                                            <span className='phyellow p-0'>
                                                              {
                                                                tradeHistoryObj.leverage
                                                              }
                                                              x
                                                            </span>
                                                          </td>
                                                          <td>
                                                            {tradeHistoryObj.side ===
                                                            'BUY' ? (
                                                              <span className='phproi'>
                                                                Buy
                                                              </span>
                                                            ) : (
                                                              <span className='phshort'>
                                                                Sell
                                                              </span>
                                                            )}
                                                          </td>
                                                          <td>
                                                            {Math.abs(
                                                              tradeHistoryObj.amount
                                                            )}
                                                          </td>
                                                          <td>
                                                            {formatPrices(
                                                              tradeHistoryObj.entryPrice
                                                            )}
                                                          </td>
                                                          <td>
                                                            {new Date(
                                                              tradeHistoryObj.openTime
                                                            ).toLocaleString()}
                                                          </td>
                                                          <td
                                                            style={{
                                                              whiteSpace:
                                                                'nowrap',
                                                            }}
                                                            className={
                                                              tradeHistoryObj.pnl <
                                                              0
                                                                ? 'phnroi'
                                                                : 'phproi'
                                                            }
                                                          >
                                                            {' '}
                                                            {tradeHistoryObj.pnl.toLocaleString(
                                                              'en-US',
                                                              {
                                                                style:
                                                                  'currency',
                                                                currency: 'USD',
                                                                minimumFractionDigits: 2,
                                                                maximumFractionDigits: 2,
                                                              }
                                                            )}{' '}
                                                          </td>
                                                          <td>
                                                            {tradeHistoryObj.days ===
                                                            1
                                                              ? '< 1 day'
                                                              : `< ${tradeHistoryObj.days} days`}
                                                          </td>
                                                        </tr>
                                                      )
                                                    )}
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        )}
                                      </>
                                    ))}
                                </table>
                              </div>
                              <div className='showing-c'>
                                <p
                                  style={{
                                    justifyContent: 'end',
                                    alignItems: 'center',
                                    padding: '5px',
                                  }}
                                  onRowsPerPageChange={(e) => {
                                    const newRecordsPerPage = Number(
                                      e.target.value
                                    );
                                    setRecordsPerPagePH(newRecordsPerPage);
                                    setcurrentPagePH(1);
                                    let direction, sortByColumn;
                                    if (sortPositionConfiguration) {
                                      direction =
                                        sortPositionConfiguration.direction ===
                                        'descending'
                                          ? 'desc'
                                          : '';
                                      sortByColumn =
                                        sortPositionConfiguration.key;
                                    }
                                    fetchLeaderPositionHistoryData(
                                      1,
                                      sortByColumn,
                                      direction,
                                      newRecordsPerPage
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    )}

                    {activeHistoryTable === 'trade' && (
                      <>
                        {isTradeHistoryLoading ? (
                          <Loader />
                        ) : (
                          <>
                            <div className='table-main'>
                              <div className='table-fn'>
                                <table className='table-user'>
                                  <tr>
                                    <th>
                                      <button
                                        type='button'
                                        onClick={() => {
                                          requestSortTH('symbol');
                                          fetchSortedLeaderTradeHistoryData(
                                            'Symbol'
                                          );
                                        }}
                                        className={getClassNamesFor(
                                          sortConfigTH,
                                          'symbol'
                                        )}
                                      >
                                        Symbol
                                      </button>
                                    </th>
                                    <th>
                                      <button
                                        type='button'
                                        className={getClassNamesFor(
                                          sortConfigTH,
                                          'side'
                                        )}
                                      >
                                        Side
                                      </button>
                                    </th>
                                    <th>
                                      <button
                                        type='button'
                                        onClick={() => {
                                          requestSortTH('amount');
                                          fetchSortedLeaderTradeHistoryData(
                                            'Amount'
                                          );
                                        }}
                                        className={getClassNamesFor(
                                          sortConfigTH,
                                          'amount'
                                        )}
                                      >
                                        Size
                                      </button>
                                    </th>
                                    <th>
                                      <button
                                        type='button'
                                        onClick={() => {
                                          requestSortTH('entryPrice');
                                          fetchSortedLeaderTradeHistoryData(
                                            'EntryPrice'
                                          );
                                        }}
                                        className={getClassNamesFor(
                                          sortConfigTH,
                                          'entryPrice'
                                        )}
                                      >
                                        Price
                                      </button>
                                    </th>
                                    <th>
                                      <button
                                        type='button'
                                        onClick={() => {
                                          requestSortTH('openTime');
                                          fetchSortedLeaderTradeHistoryData(
                                            'OpenTime'
                                          );
                                        }}
                                        className={getClassNamesFor(
                                          sortConfigTH,
                                          'openTime'
                                        )}
                                      >
                                        Time
                                      </button>
                                    </th>
                                    <th>
                                      <button
                                        type='button'
                                        onClick={() => {
                                          requestSortTH('pnl');
                                          fetchSortedLeaderTradeHistoryData(
                                            'Pnl'
                                          );
                                        }}
                                        className={getClassNamesFor(
                                          sortConfigTH,
                                          'pnl'
                                        )}
                                      >
                                        PNL
                                      </button>
                                    </th>
                                    <th>
                                      <button
                                        type='button'
                                        onClick={() => {
                                          requestSortTH('days');
                                          fetchSortedLeaderTradeHistoryData(
                                            'Days'
                                          );
                                        }}
                                        className={getClassNamesFor(
                                          sortConfigTH,
                                          'days'
                                        )}
                                      >
                                        Duration
                                      </button>
                                    </th>
                                  </tr>
                                  {tradeHistorydata &&
                                    tradeHistorydata.map((object) => (
                                      <tr>
                                        <td className='phsymbol'>
                                          <p>{object.symbol} Perpetual</p>
                                          <span className='phyellow'>
                                            {object.leverage}x
                                          </span>
                                        </td>
                                        <td>
                                          {object.side === 'BUY' ? (
                                            <span className='phproi'>Buy</span>
                                          ) : (
                                            <span className='phshort'>
                                              Sell
                                            </span>
                                          )}
                                          |
                                        </td>
                                        <td>{Math.abs(object.amount)}</td>
                                        <td>
                                          {formatPrices(object.entryPrice)}
                                        </td>
                                        <td>
                                          {new Date(
                                            object.openTime
                                          ).toLocaleString()}
                                        </td>
                                        <td
                                          style={{ whiteSpace: 'nowrap' }}
                                          className={
                                            object.pnl < 0 ? 'phnroi' : 'phproi'
                                          }
                                        >
                                          {' '}
                                          {object.pnl.toLocaleString('en-US', {
                                            style: 'currency',
                                            currency: 'USD',
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          })}{' '}
                                        </td>
                                        <td>
                                          {object.days === 1
                                            ? '< 1 day'
                                            : `< ${object.days} days`}
                                        </td>
                                      </tr>
                                    ))}
                                </table>
                              </div>
                              <div className='showing-c'>
                                <p
                                  style={{
                                    justifyContent: 'end',
                                    alignItems: 'center',
                                    padding: '5px',
                                  }}
                                ></p>
                              </div>

                              <PaginationRow
                                currentPage={currentPageTH}
                                rowsPerPage={recordsPerPageTH}
                                totalItems={totalRecordsTH}
                                onPageChange={(newPage) => {
                                  setcurrentPageTH(newPage);
                                  let direction, sortByColumn;
                                  if (sortTradeConfiguration) {
                                    direction =
                                      sortTradeConfiguration.direction ===
                                      'descending'
                                        ? 'desc'
                                        : '';
                                    sortByColumn = sortTradeConfiguration.key;
                                  }
                                  fetchLeaderTradeHistoryData(
                                    newPage,
                                    sortByColumn,
                                    direction
                                  );
                                }}
                                onRowsPerPageChange={(e) => {
                                  const newRecordsPerPage = Number(
                                    e.target.value
                                  );
                                  setRecordsPerPageTH(newRecordsPerPage);
                                  setcurrentPageTH(1);
                                  let direction, sortByColumn;
                                  if (sortTradeConfiguration) {
                                    direction =
                                      sortTradeConfiguration.direction ===
                                      'descending'
                                        ? 'desc'
                                        : '';
                                    sortByColumn = sortTradeConfiguration.key;
                                  }
                                  fetchLeaderTradeHistoryData(
                                    1,
                                    sortByColumn,
                                    direction,
                                    newRecordsPerPage
                                  );
                                }}
                              />
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              )}
            </>
          ) : (
            <>
              {!hasSubscription && hasSubscription !== null && (
                <>
                  <div
                    className={`${!hasSubscription ? 'chart-overlay' : ''}`}
                    style={{ userSelect: 'none' }}
                  >
                    <div
                      className={`${
                        !hasSubscription ? 'overlay-content' : 'd-none'
                      }`}
                    >
                      {/* <p>To see advanced statistics, performance charts, trading history and receive real-time notifications, you need to upgrade to Tycoon premium Membership.</p> */}
                      <p>
                        To see advanced trading history and receive real-time
                        notifications, you need to upgrade to Tycoon premium
                        Membership.
                      </p>
                      <Button
                        variant='warning'
                        data-bs-toggle='modal'
                        data-bs-target='#exampleModal'
                      >
                        Upgrade Now
                      </Button>
                    </div>

                    {/********************************* Positions History *********************************/}
                    <div className='positions-historys'>
                      <h3>Positions History </h3>

                      <div className='table-main'>
                        <div className='table-fn'>
                          <table className='table-user'>
                            <tr>
                              <th>Symbol</th>
                              <th>Size</th>
                              <th> Entry Price </th>
                              <th>Open Time</th>
                              <th>Close Time</th>
                              <th>PNL</th>
                              <th>Duration</th>
                            </tr>
                            <tr>
                              <td className='phsymbol'>
                                <p>Etherum Perpetual</p>
                                <span className='phnroi'>Long</span>|
                                <span className='phyellow'>10x</span>
                              </td>
                              <td>2.00</td>
                              <td>2.00</td>
                              <td>12-12-2012</td>
                              <td>12-12-2012</td>
                              <td className='phnroi'> $2 </td>
                              <td>2 Days</td>
                            </tr>
                          </table>
                        </div>
                        <div className='showing-c'>
                          <p>
                            {' '}
                            Showing 1-03 of 1523 <span> ＜ ＞ </span>{' '}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {hasSubscription && hasSubscription !== null && (
                <div>
                  {/********************************* Positions History *********************************/}
                  <div className='positions-historys'>
                    <div className='position-historys-tab'>
                      <HistoryHeader
                        label='Positions History'
                        active={activeHistoryTable === 'position'}
                        onClick={() => setActiveHistoryTable('position')}
                      />
                      <HistoryHeader
                        label='Trades History'
                        active={activeHistoryTable === 'trade'}
                        onClick={() => TradeHistoryTabActive()}
                      />
                    </div>

                    {activeHistoryTable === 'position' && (
                      <>
                        {isPositionHistoryLoading ? (
                          <Loader />
                        ) : (
                          <>
                            <div className='table-main'>
                              <div className='table-fn'>
                                <table className='table-user'>
                                  <tr>
                                    <th>
                                      <button
                                        type='button'
                                        onClick={() => {
                                          requestSortPH('symbol');
                                          fetchSortedLeaderPositionHistoryData(
                                            'Symbol'
                                          );
                                        }}
                                        className={getClassNamesFor(
                                          sortConfigPH,
                                          'symbol'
                                        )}
                                      >
                                        Symbol
                                      </button>
                                    </th>
                                    <th>
                                      <button
                                        type='button'
                                        onClick={() => {
                                          requestSortPH('amount');
                                          fetchSortedLeaderPositionHistoryData(
                                            'Amount'
                                          );
                                        }}
                                        className={getClassNamesFor(
                                          sortConfigPH,
                                          'amount'
                                        )}
                                      >
                                        Size
                                      </button>
                                    </th>
                                    <th>
                                      <button
                                        type='button'
                                        onClick={() => {
                                          requestSortPH('entryPrice');
                                          fetchSortedLeaderPositionHistoryData(
                                            'EntryPrice'
                                          );
                                        }}
                                        className={getClassNamesFor(
                                          sortConfigPH,
                                          'entryPrice'
                                        )}
                                      >
                                        Entry Price
                                      </button>
                                    </th>
                                    <th>
                                      <button
                                        type='button'
                                        onClick={() => {
                                          requestSortPH('closePrice');
                                          fetchSortedLeaderPositionHistoryData(
                                            'ClosePrice'
                                          );
                                        }}
                                        className={getClassNamesFor(
                                          sortConfigPH,
                                          'closePrice'
                                        )}
                                      >
                                        Close Price
                                      </button>
                                    </th>
                                    <th>
                                      <button
                                        type='button'
                                        onClick={() => {
                                          requestSortPH('openTime');
                                          fetchSortedLeaderPositionHistoryData(
                                            'OpenTime'
                                          );
                                        }}
                                        className={getClassNamesFor(
                                          sortConfigPH,
                                          'openTime'
                                        )}
                                      >
                                        Open Time
                                      </button>
                                    </th>
                                    <th>
                                      <button
                                        type='button'
                                        onClick={() => {
                                          requestSortPH('closeTime');
                                          fetchSortedLeaderPositionHistoryData(
                                            'CloseTime'
                                          );
                                        }}
                                        className={getClassNamesFor(
                                          sortConfigPH,
                                          'closeTime'
                                        )}
                                      >
                                        Close Time
                                      </button>
                                    </th>
                                    <th>
                                      <button
                                        type='button'
                                        onClick={() => {
                                          requestSortPH('pnl');
                                          fetchSortedLeaderPositionHistoryData(
                                            'Pnl'
                                          );
                                        }}
                                        className={getClassNamesFor(
                                          sortConfigPH,
                                          'pnl'
                                        )}
                                      >
                                        PNL
                                      </button>
                                    </th>
                                    <th>
                                      <button
                                        type='button'
                                        onClick={() => {
                                          requestSortPH('days');
                                          fetchSortedLeaderPositionHistoryData(
                                            'Days'
                                          );
                                        }}
                                        className={getClassNamesFor(
                                          sortConfigPH,
                                          'days'
                                        )}
                                      >
                                        Duration
                                      </button>
                                    </th>
                                  </tr>
                                  {positionHistorydata &&
                                    positionHistorydata.map((object, index) => (
                                      <>
                                        <tr
                                          onClick={() =>
                                            handlePositionHistoryRowClick(index)
                                          }
                                        >
                                          <td>
                                            <div
                                              className='row'
                                              style={{ paddingTop: '15px' }}
                                            >
                                              <div style={{ display: 'flex' }}>
                                                <div>
                                                  <img
                                                    src={
                                                      expandedTradeRow === index
                                                        ? vectorArrowUp
                                                        : vectorArrow
                                                    }
                                                    alt='Expanded Arrow for Trade History'
                                                  />
                                                </div>

                                                <div className='phsymbol override'>
                                                  <p>
                                                    {object.symbol} Perpetual
                                                  </p>
                                                  <span
                                                    style={{ fontSize: '12px' }}
                                                  >
                                                    {object.isLong ? (
                                                      <span className='phproi pl-3'>
                                                        Long
                                                      </span>
                                                    ) : (
                                                      <span className='phshort'>
                                                        Short
                                                      </span>
                                                    )}
                                                    |
                                                    <span className='phyellow'>
                                                      {object.leverage}x
                                                    </span>
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          </td>
                                          <td>{Math.abs(object.amount)}</td>
                                          <td>
                                            {formatPrices(object.entryPrice)}
                                          </td>
                                          <td>
                                            {formatPrices(object.markPrice)}
                                          </td>
                                          <td>
                                            {new Date(
                                              object.openTime
                                            ).toLocaleString()}
                                          </td>
                                          <td>
                                            {new Date(
                                              object.closeTime
                                            ).toLocaleString()}
                                          </td>
                                          <td
                                            style={{ whiteSpace: 'nowrap' }}
                                            className={
                                              object.pnl < 0
                                                ? 'phnroi'
                                                : 'phproi'
                                            }
                                          >
                                            {' '}
                                            {object.pnl.toLocaleString(
                                              'en-US',
                                              {
                                                style: 'currency',
                                                currency: 'USD',
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                              }
                                            )}{' '}
                                          </td>
                                          <td>
                                            {object.days === 1
                                              ? '< 1 day'
                                              : `< ${object.days} days`}
                                          </td>
                                        </tr>
                                        {expandedTradeRow === index && (
                                          <tr>
                                            <td colSpan={9}>
                                              <table className='expandable-table'>
                                                <thead>
                                                  <tr>
                                                    <th
                                                      style={{ width: '20%' }}
                                                    >
                                                      Symbol
                                                    </th>
                                                    <th>Side</th>
                                                    <th>Size</th>
                                                    <th>Price</th>
                                                    <th
                                                      style={{ width: '15%' }}
                                                    >
                                                      Time
                                                    </th>
                                                    <th>ROI</th>
                                                    <th>PNL</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {object.tradeHistoryList &&
                                                    object.tradeHistoryList.map(
                                                      (tradeHistoryObj) => (
                                                        <tr>
                                                          <td className='phsymbol'>
                                                            <p>
                                                              {
                                                                tradeHistoryObj.symbol
                                                              }{' '}
                                                              Perpetual
                                                            </p>
                                                            <span className='phyellow p-0'>
                                                              {
                                                                tradeHistoryObj.leverage
                                                              }
                                                              x
                                                            </span>
                                                          </td>
                                                          <td>
                                                            {tradeHistoryObj.side ===
                                                            'BUY' ? (
                                                              <span className='phproi'>
                                                                Buy
                                                              </span>
                                                            ) : (
                                                              <span className='phshort'>
                                                                Sell
                                                              </span>
                                                            )}
                                                          </td>
                                                          <td>
                                                            {Math.abs(
                                                              tradeHistoryObj.amount
                                                            )}
                                                          </td>
                                                          <td>
                                                            {formatPrices(
                                                              tradeHistoryObj.entryPrice
                                                            )}
                                                          </td>
                                                          <td>
                                                            {new Date(
                                                              tradeHistoryObj.openTime
                                                            ).toLocaleString()}
                                                          </td>
                                                          <td
                                                            style={{
                                                              whiteSpace:
                                                                'nowrap',
                                                            }}
                                                            className={
                                                              tradeHistoryObj.leaderTradeStatus !==
                                                                'CLOSED' &&
                                                              tradeHistoryObj.leaderTradeStatus !==
                                                                'DECREASEPOSITION'
                                                                ? 'A'
                                                                : tradeHistoryObj.roe <
                                                                  0
                                                                ? 'phnroi'
                                                                : 'phproi'
                                                            }
                                                          >
                                                            {' '}
                                                            {tradeHistoryObj.leaderTradeStatus ===
                                                              'CLOSED' ||
                                                            tradeHistoryObj.leaderTradeStatus ===
                                                              'DECREASEPOSITION'
                                                              ? `${(
                                                                  tradeHistoryObj.roe *
                                                                  100
                                                                ).toFixed(2)}%`
                                                              : 'N/A'}{' '}
                                                          </td>
                                                          <td
                                                            style={{
                                                              whiteSpace:
                                                                'nowrap',
                                                            }}
                                                            className={
                                                              tradeHistoryObj.leaderTradeStatus !==
                                                                'CLOSED' &&
                                                              tradeHistoryObj.leaderTradeStatus !==
                                                                'DECREASEPOSITION'
                                                                ? 'A'
                                                                : tradeHistoryObj.pnl <
                                                                  0
                                                                ? 'phnroi'
                                                                : 'phproi'
                                                            }
                                                          >
                                                            {' '}
                                                            {tradeHistoryObj.leaderTradeStatus ===
                                                              'CLOSED' ||
                                                            tradeHistoryObj.leaderTradeStatus ===
                                                              'DECREASEPOSITION'
                                                              ? tradeHistoryObj.pnl.toLocaleString(
                                                                  'en-US',
                                                                  {
                                                                    style:
                                                                      'currency',
                                                                    currency:
                                                                      'USD',
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2,
                                                                  }
                                                                )
                                                              : 'N/A'}{' '}
                                                          </td>
                                                        </tr>
                                                      )
                                                    )}
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        )}
                                      </>
                                    ))}
                                </table>
                              </div>
                              <div className='showing-c'>
                                <p
                                  style={{
                                    justifyContent: 'end',
                                    alignItems: 'center',
                                    padding: '5px',
                                  }}
                                  onRowsPerPageChange={(e) => {
                                    const newRecordsPerPage = Number(
                                      e.target.value
                                    );
                                    setRecordsPerPagePH(newRecordsPerPage);
                                    setcurrentPagePH(1);
                                    let direction, sortByColumn;
                                    if (sortPositionConfiguration) {
                                      direction =
                                        sortPositionConfiguration.direction ===
                                        'descending'
                                          ? 'desc'
                                          : '';
                                      sortByColumn =
                                        sortPositionConfiguration.key;
                                    }
                                    fetchLeaderPositionHistoryData(
                                      1,
                                      sortByColumn,
                                      direction,
                                      newRecordsPerPage
                                    );
                                  }}
                                />
                              </div>
                              <PaginationRow
                                currentPage={currentPagePH}
                                rowsPerPage={recordsPerPagePH}
                                totalItems={totalRecordsPH}
                                onPageChange={(newPage) => {
                                  setcurrentPagePH(newPage);
                                  let direction, sortByColumn;
                                  if (sortPositionConfiguration) {
                                    direction =
                                      sortPositionConfiguration.direction ===
                                      'descending'
                                        ? 'desc'
                                        : '';
                                    sortByColumn =
                                      sortPositionConfiguration.key;
                                  }
                                  fetchLeaderPositionHistoryData(
                                    newPage,
                                    sortByColumn,
                                    direction,
                                    recordsPerPagePH
                                  );
                                }}
                                onRowsPerPageChange={(e) => {
                                  const newRecordsPerPage = Number(
                                    e.target.value
                                  );
                                  setRecordsPerPagePH(newRecordsPerPage);
                                  setcurrentPagePH(1);
                                  let direction, sortByColumn;
                                  if (sortPositionConfiguration) {
                                    direction =
                                      sortPositionConfiguration.direction ===
                                      'descending'
                                        ? 'desc'
                                        : '';
                                    sortByColumn =
                                      sortPositionConfiguration.key;
                                  }
                                  fetchLeaderPositionHistoryData(
                                    1,
                                    sortByColumn,
                                    direction,
                                    newRecordsPerPage
                                  );
                                }}
                              />
                            </div>
                          </>
                        )}
                      </>
                    )}

                    {activeHistoryTable === 'trade' && (
                      <>
                        {isTradeHistoryLoading ? (
                          <Loader />
                        ) : (
                          <>
                            <div className='table-main'>
                              <div className='table-fn'>
                                <table className='table-user'>
                                  <tr>
                                    <th>
                                      <button
                                        type='button'
                                        onClick={() => {
                                          requestSortTH('symbol');
                                          fetchSortedLeaderTradeHistoryData(
                                            'Symbol'
                                          );
                                        }}
                                        className={getClassNamesFor(
                                          sortConfigTH,
                                          'symbol'
                                        )}
                                      >
                                        Symbol
                                      </button>
                                    </th>
                                    <th>
                                      <button
                                        type='button'
                                        className={getClassNamesFor(
                                          sortConfigTH,
                                          'side'
                                        )}
                                      >
                                        Side
                                      </button>
                                    </th>
                                    <th>
                                      <button
                                        type='button'
                                        onClick={() => {
                                          requestSortTH('amount');
                                          fetchSortedLeaderTradeHistoryData(
                                            'Amount'
                                          );
                                        }}
                                        className={getClassNamesFor(
                                          sortConfigTH,
                                          'amount'
                                        )}
                                      >
                                        Size
                                      </button>
                                    </th>
                                    <th>
                                      <button
                                        type='button'
                                        onClick={() => {
                                          requestSortTH('entryPrice');
                                          fetchSortedLeaderTradeHistoryData(
                                            'EntryPrice'
                                          );
                                        }}
                                        className={getClassNamesFor(
                                          sortConfigTH,
                                          'entryPrice'
                                        )}
                                      >
                                        Price
                                      </button>
                                    </th>
                                    <th>
                                      <button
                                        type='button'
                                        onClick={() => {
                                          requestSortTH('openTime');
                                          fetchSortedLeaderTradeHistoryData(
                                            'OpenTime'
                                          );
                                        }}
                                        className={getClassNamesFor(
                                          sortConfigTH,
                                          'openTime'
                                        )}
                                      >
                                        Time
                                      </button>
                                    </th>
                                    <th>
                                      <button
                                        type='button'
                                        onClick={() => {
                                          requestSortTH('pnl');
                                          fetchSortedLeaderTradeHistoryData(
                                            'Roe'
                                          );
                                        }}
                                        className={getClassNamesFor(
                                          sortConfigTH,
                                          'roe'
                                        )}
                                      >
                                        ROI
                                      </button>
                                    </th>
                                    <th>
                                      <button
                                        type='button'
                                        onClick={() => {
                                          requestSortTH('pnl');
                                          fetchSortedLeaderTradeHistoryData(
                                            'Pnl'
                                          );
                                        }}
                                        className={getClassNamesFor(
                                          sortConfigTH,
                                          'pnl'
                                        )}
                                      >
                                        PNL
                                      </button>
                                    </th>
                                  </tr>
                                  {tradeHistorydata &&
                                    tradeHistorydata.map((object) => (
                                      <tr>
                                        <td className='phsymbol'>
                                          <p>{object.symbol} Perpetual</p>
                                          <span className='phyellow p-0'>
                                            {object.leverage}x
                                          </span>
                                        </td>
                                        <td>
                                          {object.side === 'BUY' ? (
                                            <span className='phproi'>Buy</span>
                                          ) : (
                                            <span className='phshort'>
                                              Sell
                                            </span>
                                          )}
                                        </td>
                                        <td>{Math.abs(object.amount)}</td>
                                        <td>
                                          {formatPrices(object.entryPrice)}
                                        </td>
                                        <td>
                                          {new Date(
                                            object.openTime
                                          ).toLocaleString()}
                                        </td>
                                        <td
                                          style={{ whiteSpace: 'nowrap' }}
                                          className={
                                            object.leaderTradeStatus !==
                                              'CLOSED' &&
                                            object.leaderTradeStatus !==
                                              'DECREASEPOSITION'
                                              ? ''
                                              : object.roe < 0
                                              ? 'phnroi'
                                              : 'phproi'
                                          }
                                        >
                                          {' '}
                                          {object.leaderTradeStatus ===
                                            'CLOSED' ||
                                          object.leaderTradeStatus ===
                                            'DECREASEPOSITION'
                                            ? `${(object.roe * 100).toFixed(
                                                2
                                              )}%`
                                            : 'N/A'}{' '}
                                        </td>
                                        <td
                                          style={{ whiteSpace: 'nowrap' }}
                                          className={
                                            object.leaderTradeStatus !==
                                              'CLOSED' &&
                                            object.leaderTradeStatus !==
                                              'DECREASEPOSITION'
                                              ? ''
                                              : object.pnl < 0
                                              ? 'phnroi'
                                              : 'phproi'
                                          }
                                        >
                                          {' '}
                                          {object.leaderTradeStatus ===
                                            'CLOSED' ||
                                          object.leaderTradeStatus ===
                                            'DECREASEPOSITION'
                                            ? object.pnl.toLocaleString(
                                                'en-US',
                                                {
                                                  style: 'currency',
                                                  currency: 'USD',
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                }
                                              )
                                            : 'N/A'}{' '}
                                        </td>
                                      </tr>
                                    ))}
                                </table>
                              </div>
                              <div className='showing-c'>
                                <p
                                  style={{
                                    justifyContent: 'end',
                                    alignItems: 'center',
                                    padding: '5px',
                                  }}
                                ></p>
                              </div>

                              <PaginationRow
                                currentPage={currentPageTH}
                                rowsPerPage={recordsPerPageTH}
                                totalItems={totalRecordsTH}
                                onPageChange={(newPage) => {
                                  setcurrentPageTH(newPage);
                                  let direction, sortByColumn;
                                  if (sortTradeConfiguration) {
                                    direction =
                                      sortTradeConfiguration.direction ===
                                      'descending'
                                        ? 'desc'
                                        : '';
                                    sortByColumn = sortTradeConfiguration.key;
                                  }
                                  fetchLeaderTradeHistoryData(
                                    newPage,
                                    sortByColumn,
                                    direction
                                  );
                                }}
                                onRowsPerPageChange={(e) => {
                                  const newRecordsPerPage = Number(
                                    e.target.value
                                  );
                                  setRecordsPerPageTH(newRecordsPerPage);
                                  setcurrentPageTH(1);
                                  let direction, sortByColumn;
                                  if (sortTradeConfiguration) {
                                    direction =
                                      sortTradeConfiguration.direction ===
                                      'descending'
                                        ? 'desc'
                                        : '';
                                    sortByColumn = sortTradeConfiguration.key;
                                  }
                                  fetchLeaderTradeHistoryData(
                                    1,
                                    sortByColumn,
                                    direction,
                                    newRecordsPerPage
                                  );
                                }}
                              />
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              )}
            </>
          )}
        </div>

        <div
          class='modal fade'
          id='exampleModal'
          tabIndex='-1'
          aria-labelledby='exampleModalLabel'
          aria-hidden='true'
        >
          <div
            class='modal-dialog'
            style={{ marginRight: '0', display: 'none' }}
          ></div>
          <div class='modal-body'>
            <Pricing />
          </div>
        </div>
        <InformationMessageModal
          isInfoModalOpen={isInfoModalOpen}
          closeInfoModal={closeInfoModal}
          headingContent={'Warning'}
          bodyContent={
            'You reached the limit of following 10 Traders at the same time. Please unfollow a trader, to follow a new one.'
          }
        />
        {isConnectTelegramModalOpen && (
          <>
            <ConnectTelegramModal
              isOpen={isConnectTelegramModalOpen}
              closeConnectTelegramModal={closeConnectTelegramModal}
              onTelegramLinkClicked={onTelegramLinkClicked}
              userInfo={userInfo}
              botNameRefer={botNameRefer}
              isBotNameCopied={isBotNameCopied}
              copyTGBotnameToClipboard={copyTGBotnameToClipboard}
              refer={refer}
              isSecretCopied={isSecretCopied}
              copyToClipboard={copyToClipboard}
            />
          </>
        )}
      </div>
    </>
  );
};
