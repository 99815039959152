import { useAuth0 } from '@auth0/auth0-react';
import { ThemeProvider } from '@mui/material';
import axios from 'axios';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import './mui-lab-overrides.css';
import GoogleTagManager from './Components/Common/GoogleTagManager';
import { Home } from './Components/Home';
import { Pricing } from './Components/Pricing';
import { Profile } from './Components/Profile';
import ProtectedRoute from './Components/ProtectedRoute';
import { Setting } from './Components/Setting';
import { UnverifiedEmailPage } from './Components/UnverifiedEmailPage';
import { updateUser } from './Redux/leaderboardAction';
import theme from './theme';

function App() {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      await userApi();
    };
    fetchData();
  }, [isAuthenticated]);

  const userApi = async (e) => {
    if (isAuthenticated) {
      const token = await getAccessTokenSilently();

      axios
        .get(`${window._env_.REACT_APP_API_URL}/Users/GetUserInfo`, {
          headers: {
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          dispatch(updateUser(response.data));
        })
        .catch((error) => {
          // Handle the error
          console.log(error);
        });
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route path='/' element={<ProtectedRoute component={Home} />} />
        <Route
          path='/profile/:id'
          element={<ProtectedRoute component={Profile} />}
        />

        {/* <Route path="/signup" element={<Signup />} /> */}
        {/* <Route path="/login" element={<Login />} /> */}

        <Route
          path='/setting'
          element={<ProtectedRoute component={Setting} />}
        />
        <Route
          path='/pricing'
          element={<ProtectedRoute component={Pricing} />}
        />
        <Route path='/unverifiedemailpage' element={<UnverifiedEmailPage />} />
      </Routes>
      <GoogleTagManager />
    </ThemeProvider>
  );
}

export default App;
