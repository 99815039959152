import React from "react";

class Mobbrn extends React.Component {
  constructor(props) {
    super(props);
    if (props.activeBtn === "Favorite") {
      this.state = { addClass: props.isFavorite };
    } else {
      this.state = { addClass: props.isFollow };
    }

  }
  async toggle(e) {
    e.preventDefault();
    e.stopPropagation();
    if (this.props.activeBtn === "Favorite") {
      await this.props.toggleFavorite(this.props.leaderId, e);
    } else {
      if (!this.props.hasSubscription && !this.props.hasFreeSubscription && !this.state.addClass) {
        e.preventDefault();
        this.props.showStripeModal();
      } else {
        let result = await this.props.toggleFollower(this.props.leaderId, e);

        if (result && this.props.activeBtn !== "Follower") {
          this.setState({ addClass: !this.state.addClass });
        }
      }

    }
  }

  async openTGModal(e) {
    e.preventDefault();
    e.stopPropagation();
    this.props.openConnectTelegramModal()
  }

  render() {
    let boxClass = ["box"];
    if (this.state.addClass) {
      boxClass.push('green');
    }
    return (
      <>
        {!this.props.isFreeSubscriptionExpired ? (
          <>
            {!this.props.hasSubscription && !this.props.hasFreeSubscription ? (
              <div
                className={boxClass.join(' ')}
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
              >
                +
                <br />
              </div>
            ) : (
              <>
                {!this.props.hasUserTGChatId ? (
                  <>
                    <div className={boxClass.join(' ')} onClick={this.openTGModal.bind(this)}>
                      {this.state.addClass ? "-" : "+"}
                      <br />
                    </div>
                  </>
                ) : (
                  <>
                    <div className={boxClass.join(' ')} onClick={this.toggle.bind(this)}>
                      {this.state.addClass ? "-" : "+"}
                      <br />
                    </div>
                  </>
                )}
              </>
            )}
          </>
        ) : (
          <>
            {this.props.hasSubscription ? (
              <>
                {!this.props.hasUserTGChatId ? (
                  <>
                    <div className={boxClass.join(' ')} onClick={this.openTGModal.bind(this)}>
                      {this.state.addClass ? "-" : "+"}
                      <br />
                    </div>
                  </>
                ) : (
                  <>
                    <div className={boxClass.join(' ')} onClick={this.toggle.bind(this)}>
                      {this.state.addClass ? "-" : "+"}
                      <br />
                    </div>
                  </>
                )}
              </>
            ) : (
              <div
                className={boxClass.join(' ')}
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
              >
                +
                <br />
              </div>
            )}
          </>
        )}
      </>
    );
  }
}


export default Mobbrn