import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate, Link } from "react-router-dom"

import { TabList } from "@mui/lab";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";

import "../css/Leaderboard.css";
import styles from "../css/setting.module.css";

import Mobbtn from "./Mobbtn";
import Loader from './Loader';
import { Pricing } from "./Pricing";

import { InformationMessageModal } from './Common/InformationMessage';
import { Currency, Percentage } from './Common/Formatting';
import { Dropdown } from 'bootstrap';

import {
  updateSearchName, updateSelectedPeriodType, updateSelectedProfileType,
  updateSelectedSortDirection, updateSelectedSortType,
  updateSelectedStatsType, updateSubscriptionFlag, updateUser,
  updateCurrentPage, updateRowsPerPage, updateTotalItems,
  updateSkipRecords, updatePreviousSelectedProfileType,
  updateOnlyProfitableFilter, updateTrackingDaysFilter, updatePnlHigherThanFilter,
  updateTradesLastSevenDaysFilter, updateWinRateFilter, updateIsFiltersActivated,
  updateResetCustomNumberFlag
} from "../Redux/leaderboardAction";

import { PaginationRow } from './PaginationComponent'


import leadericon from "./Images/BinanceFutures.svg";
import filterIcon from "./Images/filter.svg";
import crossIcon from "./Images/crossIconSmall.svg";
import leadericon1 from "./Images/table-logo.png";
import dummy from "./Images/defaultProfile.png";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortUp, faSortDown, faSearch } from '@fortawesome/free-solid-svg-icons';
import ConnectTelegramModal from './ConnectTelegramModal';
import { dispatch, pointer } from "d3";

export const ProductSort1 = () => {

  const search = useSelector(state => state.search);;
  const statsType = useSelector(state => state.statsType);
  const periodType = useSelector(state => state.periodType);
  const sortType = useSelector(state => state.sortType);
  const sortDirection = useSelector(state => state.sortDirection);

  const selectedProfileType = useSelector(state => state.selectedProfileType)
  const previousSelectedProfileType = useSelector(state => state.previousSelectedProfileType)
  const [prevoiusSearch, setPrevoiusSearch] = useState('');

  const [sortedItems, setSortedItems] = useState([]);
  const [leaderboardUpdateTime, setLeaderboardUpdateTime] = useState(null);
  const dispatch = useDispatch()
  const {
    isAuthenticated,
    getAccessTokenSilently
  } = useAuth0();
  const [isLoading, setIsLoading] = useState(true);
  const [userInfo, setUserInfo] = useState(false);

  const [show, setShow] = useState(false);
  const closeStripeModal = () => setShow(false);
  const showStripeModal = () => setShow(true);
  const [userId, setUserId] = useState(null);
  const [userEmail, setUserEmail] = useState(null);

  const [hasSubscription, setHasSubscription] = useState(false);
  const [isLoadingProduct, setIsLoadingProduct] = useState(true);
  const [activeElement, setActiveElement] = useState(null);
  const [sortConfiguration, setSortConfiguration] = useState({});

  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const closeInfoModal = () => setIsInfoModalOpen(false);
  const OpenInfoModal = () => setIsInfoModalOpen(true);
  const [hasFreeSubscription, setHasFreeSubscription] = useState(false);
  const [isFreeSubscriptionExpired, setIsFreeSubscriptionExpired] = useState(false);
  const [hasTrialSubscription, setHasTrialSubscription] = useState(false);

  const freeSubscriptionConfig = window._env_.REACT_APP_FREE_SUBSCRIPTION_OPTIONS;
  const freeSubscriptionEnabled = freeSubscriptionConfig.freeSubscriptionEnabled;
  const freeSubscriptionStart = new Date(freeSubscriptionConfig.freeSubscriptionStart);
  const freeSubscriptionEnd = new Date(freeSubscriptionConfig.freeSubscriptionEnd);
  const userRecord = useSelector(state => state.user);
  const currentPage = useSelector(state => state.currentPage);
  const rowsPerPage = useSelector(state => state.rowsPerPage);
  const totalItems = useSelector(state => state.totalItems);
  const skipRecords = useSelector(state => state.skipRecords);

  const [isWelcomeModalOpen, setIsWelcomeModalOpen] = useState(false);

  const [isConnectTelegramModalOpen, setIsConnectTelegramModalOpen] = useState(false);
  const [isSecretCopied, setIsSecretCopied] = useState(false);
  const [isBotNameCopied, setIsBotNameCopied] = useState(false);
  const [hasUserTGChatId, setHasUserTGChatId] = useState(false);

  const telegramBotLink = window._env_.REACT_APP_TELEGRAM_BOT_LINK;

  const refer = useRef(null);
  const botNameRefer = useRef(null);

  const onlyProfitableFilter = useSelector(state => state.onlyProfitableFilter);
  const trackingDaysFilter = useSelector(state => state.trackingDaysFilter);
  const tradesLastSevenDaysFilter = useSelector(state => state.tradesLastSevenDaysFilter);
  const pnlHigherThanFilter = useSelector(state => state.pnlHigherThanFilter);
  const winRateFilter = useSelector(state => state.winRateFilter);
  const isFiltersActivated = useSelector(state => state.isFiltersActivated);
  const resetCustomNumberFlag = useSelector(state => state.resetCustomNumberFlag);

  // const trackingTimeOptions = ['1 Month', '3 Months', '6 Months'];
  // const pnlHigherThanOptions = ['$1K', '$5K', '$10K', '$25K', '$50K'];
  // const noOfTradesLast7daysOptions = ['≥ 10', '≥ 20', '≥ 40', '≥ 80', '≥ 100'];
  // const winRateOptions = ['50%', '60%', '70%', '80%'];

  const trackingTimeOptions = [
    { value: 0, label: "Select" },
    { value: 1, label: "≥ 1 Months" },
    { value: 3, label: "≥ 3 Months" },
    { value: 6, label: "≥ 6 Months" },
  ];

  const pnlHigherThanOptions = [
    { value: 0, label: "Select" },
    { value: 1000, label: "≥ $1K" },
    { value: 5000, label: "≥ $5K" },
    { value: 10000, label: "≥ $10K" },
    { value: 25000, label: "≥ $25K" },
    { value: 50000, label: "≥ $50K" },
  ];

  const noOfTradesLast7daysOptions = [
    { value: 0, label: "Select" },
    { value: 10, label: "≥ 10" },
    { value: 20, label: "≥ 20" },
    { value: 40, label: "≥ 40" },
    { value: 80, label: "≥ 80" },
    { value: 100, label: "≥ 100" },
  ];

  const winRateOptions = [
    { value: 0, label: "Select" },
    { value: 50, label: "≥ 50%" },
    { value: 60, label: "≥ 60%" },
    { value: 70, label: "≥ 70%" },
    { value: 80, label: "≥ 80%" },
  ];

  const openConnectTelegramModal = () => {
    setIsSecretCopied()
    setIsBotNameCopied()
    setIsConnectTelegramModalOpen(true);
  };
  
  const closeConnectTelegramModal = () => {
    setIsConnectTelegramModalOpen(false);
  };

  const onTelegramLinkClicked = () => {
    let botLinkWithSecret = telegramBotLink + "?start=" + userInfo?.telegramSecret
    window.open(botLinkWithSecret) //to open new page
  }

  const copyToClipboard = async (e) => {
    refer.current.select();
    await navigator.clipboard.writeText(refer.current.value);
    setIsSecretCopied(true)
  };

  const copyTGBotnameToClipboard = async (e) => {
    botNameRefer.current.select();
    await navigator.clipboard.writeText(botNameRefer.current.value);
    setIsBotNameCopied(true)
  };

  const columns = [
    { title: "Trader", class: "head_trader", sortKey: "Trader", period: "all" },
    { title: "Daily", class: "head_daily", sortKey: "Daily", period: "daily" },
    { title: "Weekly", class: "head_weekly", sortKey: "Weekly", period: "weekly" },
    { title: "Monthly", class: "head_monthy", sortKey: "Monthly", period: "monthly" },
    { title: "All Time", class: "head_alltime", sortKey: "All", period: "all" },
    { title: "Followers", class: "head_follower", sortKey: "Follower", period: "all" },
    { title: "Open Positions", class: "head_openposition", sortKey: "OpenPositions", period: "all" },
  ];

  const handleSort = async (col) => {
    let newSortDirection;
    if (sortType === col.sortKey) {
      newSortDirection = sortDirection === "asc" ? "desc" : "asc";
    } else {
      newSortDirection = "desc";
    }
    dispatch(updateSelectedSortDirection(newSortDirection));
    dispatch(updateSelectedSortType(col.sortKey));
    dispatch(updateSelectedPeriodType(col.period));
  };

  useEffect(() => {
    const fetchData = async () => {
      let skipRecordsFromAPI = skipRecords;
      if (selectedProfileType !== previousSelectedProfileType || search !== prevoiusSearch) {
        skipRecordsFromAPI = 0;
        dispatch(updateSkipRecords(0));
        if (currentPage !== 1) {
          dispatch(updateCurrentPage(1));
          return;
        }
      }
      await getLeaderBoard(skipRecordsFromAPI, sortType, sortDirection);
      dispatch(updatePreviousSelectedProfileType(selectedProfileType));
      setPrevoiusSearch(search);
    };

    fetchData();

  }, [search, statsType, periodType, sortType, sortDirection, selectedProfileType,
    currentPage, rowsPerPage, onlyProfitableFilter, trackingDaysFilter, pnlHigherThanFilter, tradesLastSevenDaysFilter, winRateFilter]);

  const handlePageChange = (pageNumber) => {
    dispatch(updateCurrentPage(pageNumber));
    dispatch(updateSkipRecords((pageNumber - 1) * rowsPerPage));
  };

  const handleRowsPerPageChange = (e) => {
    dispatch(updateRowsPerPage(parseInt(e.target.value, 10)));
    dispatch(updateCurrentPage(1));
    dispatch(updateSkipRecords(0))
  };

  const filterTypeChange = (event, newValue) => {
    dispatch(updateSelectedProfileType(newValue))
  };

  const changeState = (e) => {
    const value = e.target.value;
    dispatch(updateSelectedStatsType(value))
  };

  const changeStatsPeriod = (val) => {
    let correspondingColumn = "";
    if (val === "all") {
      correspondingColumn = columns.find(col => col.sortKey === "All");
    } else {
      correspondingColumn = columns.find(col => col.period === val);
    }
    if (correspondingColumn) {
      dispatch(updateSelectedSortType(correspondingColumn.sortKey));
    }
    dispatch(updateSelectedPeriodType(val));
  };

  useEffect(() => {
    userRecord && (userRecord?.subscription === null || !userRecord?.lifeTimePlanStripe) &&
      (userRecord?.freeSubscription?.isFreeSubscription) ? setHasFreeSubscription(true) : setHasFreeSubscription(false);

    const hasTelegramChat = userRecord && userRecord.telegramChatId !== null;
    setHasUserTGChatId(hasTelegramChat);
    setUserInfo(userRecord);
  }, [userRecord])

  useEffect(() => {
    let trialSubscriptionCondition = false;
    // Get the current date
    const currentDate = new Date();
    const trialEndDate = new Date(userInfo?.trialPeriod?.trialEnd);
    if (currentDate <= trialEndDate) {
      trialSubscriptionCondition = true;
      setHasTrialSubscription(true);
    }
    if (userInfo && (userInfo.subscription !== null || userInfo.lifeTimePlanStripe || trialSubscriptionCondition)) {
      setHasSubscription(true);
    } else {
      setHasSubscription(false);
    }
    if (userInfo && (userInfo?.subscription === null || !userInfo?.lifeTimePlanStripe) && userInfo?.freeSubscription?.isFreeSubscription) {
      setHasFreeSubscription(true);
    } else {
      setHasFreeSubscription(false);
    }
  }, [userInfo]);

  useEffect(() => {
    if (hasSubscription) {
      dispatch(updateSubscriptionFlag(hasSubscription));
    }
  }, [hasSubscription]);

  useEffect(() => {
    //initialize dropdown manually
    const dropdownTrigger = document.querySelector('[data-bs-toggle="dropdown"]');
    if (dropdownTrigger) {
      new Dropdown(dropdownTrigger);
    }

    const currentDate = new Date();

    if (freeSubscriptionEnabled && currentDate >= freeSubscriptionStart && currentDate <= freeSubscriptionEnd) {
      setIsFreeSubscriptionExpired(false);
    } else {
      setIsFreeSubscriptionExpired(true);
    }

    const handleFocus = (event) => {
      setActiveElement(event.target);
    };

    document.addEventListener('focus', handleFocus, true);

    return () => {
      document.removeEventListener('focus', handleFocus, true);
    };
  }, []);

  let source = null
  const getLeaderBoard = async (skipRecords, sortBycolumn, direction) => {

    if (selectedProfileType == '') return;

    if (isAuthenticated) {
      if (activeElement?.id !== 'leaderboardSearch') {
        setIsLoadingProduct(true);
      }

      setSortedItems([]);

      const token = await getAccessTokenSilently();
      let url = `${window._env_.REACT_APP_API_URL}/LeaderboardApi/GetLeaderboard?statsType=${statsType}&periodType=${periodType}&topCount=${rowsPerPage}`
      url += `&search=${search}&leaderType=${selectedProfileType}&skipLeaderCount=${skipRecords}&allTimeProfitable=${onlyProfitableFilter}&atleastTrackingMonth=${trackingDaysFilter}&pnlHigherThan=${pnlHigherThanFilter}&noOfTradesLast7days=${tradesLastSevenDaysFilter}&winRate=${winRateFilter}`

      if (sortBycolumn) {
        url += `&sort=${sortBycolumn}&direction=${direction}`
      }

      source?.cancel()
      source = axios.CancelToken.source();

      const data = await axios(
        url, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        cancelToken: source?.token
      }
      );

      dispatch(updateTotalItems(data.data.records));

      if (data.data.leaderBoard.entries.length === 0 && (data.data.records !== 0 || (data.data.records === 0 && currentPage !== 0))) {
        dispatch(updateCurrentPage(1));
        dispatch(updateSkipRecords(0));
      }

      let totalRecords = data.data.leaderBoard.entries;

      let count = skipRecords;
      totalRecords.forEach(element => {
        element.rowNo = ++count;
      });

      setLeaderboardUpdateTime(data.data.leaderBoard.leadersRecentUpdateTime);
      setSortedItems(totalRecords);
      setIsLoadingProduct(false);
    }
  }

  useEffect(() => {
    if (selectedProfileType !== previousSelectedProfileType || search !== prevoiusSearch) {
      dispatch(updateSkipRecords(0));
      if (currentPage !== 1) {
        dispatch(updateCurrentPage(1));
        return;
      }
    }
  }, [selectedProfileType]);

  useEffect(() => {
    if (userInfo) {
      const userIdWithAutho = userInfo.id;
      setUserId(userIdWithAutho.replace('|', ''));
      setUserEmail(userInfo.email);
      if (userInfo.isVisitFirstTime) {
        openWelcomeModal();
      }
    }
  }, [userInfo]);

  const addFollowerLeader = async (leaderId, isFollower) => {
    if (isAuthenticated) {
      const token = await getAccessTokenSilently();
      await axios
        .post(`${window._env_.REACT_APP_API_URL}/Users/AddFollowerLeader?leaderId=${leaderId}&isFollower=${isFollower}`, {}, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setIsLoading(false);
          setIsLoadingProduct(false);
        })
        .catch((error) => {
          // Handle the error
          console.log(error);
        });
    }
  };

  const toggleFollower = async (leaderId, e) => {
    let isFollowed = false;
    const itemIndex = sortedItems.findIndex(item => item.id === leaderId);
    if (itemIndex !== -1) {
      isFollowed = sortedItems[itemIndex].isFollowed;
    }

    const followersCount = userInfo.followerLeaderIds.length;

    if (!isFollowed && followersCount >= 10) {
      OpenInfoModal();
      return false;
    }

    setUserInfo(prevUserInfo => {
      const updatedFollowerLeaderIds = prevUserInfo.followerLeaderIds.includes(leaderId)
        ? prevUserInfo.followerLeaderIds.filter(id => id !== leaderId)
        : [...prevUserInfo.followerLeaderIds, leaderId];

      let updatedUserRecord = userRecord;
      updatedUserRecord.followerLeaderIds = updatedFollowerLeaderIds
      dispatch(updateUser(updatedUserRecord));

      return {
        ...prevUserInfo,
        followerLeaderIds: updatedFollowerLeaderIds,
      };
    });

    if (selectedProfileType === "Follower") {
      const updatedItems = sortedItems.filter(item => item.id !== leaderId).map((item, index) => {
        item.rowNo = index + 1
        return item
      })
      setSortedItems(updatedItems);
    } else {
      const updatedItems = [...sortedItems];
      if (itemIndex !== -1) {
        updatedItems[itemIndex].isFollowed = !updatedItems[itemIndex].isFollowed;
        setSortedItems(updatedItems);
      }
    }

    setIsLoading(false);
    if (selectedProfileType === "Follower")
      setIsLoadingProduct(true);
    let direction = sortConfiguration?.direction == 'descending' ? 'desc' : 'asc';
    if (isFollowed) {
      await addFollowerLeader(leaderId, false);
    } else {
      if (hasSubscription || (!isFreeSubscriptionExpired && hasFreeSubscription)) {
        await addFollowerLeader(leaderId, true);
      }
    }

    if (selectedProfileType === "Follower" && (isFollowed ||
      (hasSubscription || (!isFreeSubscriptionExpired && hasFreeSubscription)))) {
      if (totalItems - 1 > skipRecords || currentPage == 1) {
        await getLeaderBoard(skipRecords, sortConfiguration?.key, direction);
      } else if (currentPage > 1) {
        handlePageChange(currentPage - 1);
      }
    }

    return true;
  }

  const addFavoriteLeader = async (leaderId, isFavorite) => {
    if (isAuthenticated) {
      const token = await getAccessTokenSilently();
      await axios
        .post(`${window._env_.REACT_APP_API_URL}/Users/AddFavoriteLeader?leaderId=${leaderId}&isFavorite=${isFavorite}`, {}, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setIsLoading(false);
          setIsLoadingProduct(false);
        })
        .catch((error) => {
          // Handle the error
          console.log(error);
        });
    }
  };

  const toggleFavorite = async (leaderId, e) => {
    let isFavorite = false;
    const itemIndex = sortedItems.findIndex(item => item.id === leaderId);
    if (itemIndex !== -1) {
      isFavorite = sortedItems[itemIndex].isFavorite;
    }

    setUserInfo(prevUserInfo => {
      const updatedFavoriteLeaderIds = prevUserInfo.favoriteLeaderIds.includes(leaderId)
        ? prevUserInfo.favoriteLeaderIds.filter(id => id !== leaderId)
        : [...prevUserInfo.favoriteLeaderIds, leaderId];

      let updatedUserRecord = userRecord;
      updatedUserRecord.favoriteLeaderIds = updatedFavoriteLeaderIds
      dispatch(updateUser(updatedUserRecord));

      return {
        ...prevUserInfo,
        favoriteLeaderIds: updatedFavoriteLeaderIds,
      };
    });

    if (selectedProfileType === "Favorite") {
      const updatedItems = sortedItems.filter(item => item.id !== leaderId).map((item, index) => {
        item.rowNo = index + 1
        return item
      });
      setSortedItems(updatedItems);
    }
    setIsLoadingProduct(true);
    await addFavoriteLeader(leaderId, !isFavorite);
    let direction = sortConfiguration?.direction == 'descending' ? 'desc' : 'asc';

    if (totalItems - 1 > skipRecords || currentPage == 1) {
      await getLeaderBoard(skipRecords, sortConfiguration?.key, direction);
    } else if (currentPage > 1) {
      handlePageChange(currentPage - 1);
    }

  }

  const validateUrl = (url) => {
    try {
      new URL(url);
      return true;
    } catch (error) {
      return false;
    }
  };

  const openWelcomeModal = () => {
    setIsWelcomeModalOpen(true);
    document.body.classList.add("modal-open");
  };

  const removeFirstVisitForUser = async () => {
    try {
      if (isAuthenticated) {
        const token = await getAccessTokenSilently();
        const response = await axios.get(
          `${window._env_.REACT_APP_API_URL}/Users/RemoveFirstVisitForUser`,
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }
    }
    catch (error) {
      console.error(error);
    }
  }

  const closeWelcomeModal = () => {
    setIsWelcomeModalOpen(false);
    removeFirstVisitForUser();
    document.body.classList.remove("modal-open");
  };

  const formatDate = (date) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const day = String(d.getDate()).padStart(2, '0');
    const hours = String(d.getHours()).padStart(2, '0');
    const minutes = String(d.getMinutes()).padStart(2, '0');
    const seconds = String(d.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  const applyProfitableTradeFilter = (e) => {
    dispatch(updateOnlyProfitableFilter(e));
  }

  const handleTrackingTimeChange = (filterVal) => {
    dispatch(updateTrackingDaysFilter(parseInt(filterVal, 10)));
  };

  const handlePnlHigherThanChange = (filterVal) => {
    dispatch(updatePnlHigherThanFilter(filterVal));
  };

  const handleWinRateChange = (filterVal) => {
    dispatch(updateWinRateFilter(parseInt(filterVal, 10)));
  };

  const handleNoOfTradesLast7daysChange = (filterVal) => {
    dispatch(updateTradesLastSevenDaysFilter(filterVal));
  };


  const clearAllFilter = () => {
    dispatch(updateTrackingDaysFilter(0));
    handlePnlHigherThanChange(0);
    dispatch(updateWinRateFilter(0));
    dispatch(updateResetCustomNumberFlag(false));
    handleNoOfTradesLast7daysChange(0);
  }

  const activateAllFilter = () => {
    dispatch(updateIsFiltersActivated(!isFiltersActivated));
  }


  return (
    <>
      {
        sortedItems ? (
          <>
            <div className="product-sortss" >
              <div className="container-f">
                <div className="table_heading">
                  <div className="t-headin1">
                    <h3>Leaderboard</h3>
                  </div>
                  <div className="t-headin1">
                    <img src={leadericon} alt="" className="head-img" />
                    <img src={leadericon1} alt="" className="head-img1" />
                  </div>
                </div>

                <div className="head_flex">
                  <div className="pt-4">
                    <TabContext value={selectedProfileType}>
                      <TabList onChange={filterTypeChange}>
                        <Tab label="All Traders" value="Public" />
                        <Tab label="Favorites" value="Favorite" />
                        <Tab label="Followed" value="Follower" />
                      </TabList>
                    </TabContext>
                  </div>
                  <div className="table_top">
                    <div className="table_top_a d-flex flex-wrap">

                      <div className="table_top_sort">
                        <label htmlFor="statsType" className="label_sort">
                          Sort by
                        </label>

                        <div class="select">
                          <select name="statsType" onChange={changeState} id="format" value={statsType}>
                            <option value="ROI">% ROI</option>
                            <option value="PNL">$ PNL</option>
                          </select>
                        </div>
                      </div>
                      <div className="table_top_time">
                        <label htmlFor="time" className="label_time">
                          Time
                        </label>

                        <div class="select">
                          <StatsPeriodSelector periodType={periodType} statsPeriodChanged={(e) => changeStatsPeriod(e.target.value)} />
                        </div>
                      </div>

                      <div className="table_top_search mt-4 d-sm-none" style={{ width: 'auto' }}>
                        <div className="filterbtnWidth">
                          <button className="filtersMobBtn w-100" onClick={() => activateAllFilter()}>
                            {isFiltersActivated ? (
                              <>
                                <img src={crossIcon} alt="" className="" style={{ height: '32%' }} />
                              </>
                            ) : (
                              <>
                                <img src={filterIcon} alt="" className="" style={{ height: '32%' }} />
                              </>
                            )} Filters
                          </button>
                        </div>
                      </div>

                    </div>
                    <>
                      <div className="table_top_a extendedFilters mt-1 row d-sm-none" style={{ width: 'auto' }}>
                        {isFiltersActivated && (
                          <div className="col-md-12 col-12 m-0 p-0">

                            <div className="table_top_a row m-0 p-0">
                              <div className="row m-0 p-0 mobileFilters">
                                <div className="table_top_sort col-6 m-0">
                                  <label htmlFor="statsType" className="label_sort">
                                    Tracking Time
                                  </label>
                                  <FilterSelector name={'trackingTime'} options={trackingTimeOptions} selectedValue={trackingDaysFilter}
                                    onChange={(e) => handleTrackingTimeChange(e.target.value)} />
                                </div>

                                <div className="table_top_sort col-6 m-0">
                                  <label htmlFor="statsType" className="label_sort">
                                    Trades
                                  </label>
                                  <FilterInputSelector name={'noOfTrades'} options={noOfTradesLast7daysOptions} selectedValue={tradesLastSevenDaysFilter}
                                    handleChange={(e) => handleNoOfTradesLast7daysChange(e)} symbol="≥ "/>
                                </div>
                                <div className="table_top_sort col-6 m-0 mt-2">
                                  <label htmlFor="statsType" className="label_sort">
                                    Win rate
                                  </label>
                                  <FilterSelector name={'winRate'} options={winRateOptions} selectedValue={winRateFilter}
                                    onChange={(e) => handleWinRateChange(e.target.value)} />
                                </div>

                                <div className="table_top_sort col-6 m-0 mt-2">
                                  <label htmlFor="statsType" className="label_sort">
                                    PNL
                                  </label>
                                  <FilterInputSelector name={'PNL'} options={pnlHigherThanOptions} selectedValue={pnlHigherThanFilter}
                                    handleChange={(e) => handlePnlHigherThanChange(e)} symbol="≥ $"/>
                                </div>
                                <div className="col-6 m-0 p-0 clearFilter table_top_sort mt-2">
                                  <label className="label_sort"></label>
                                  <div style={{ height: '100%' }}>
                                    <button className="clearFiltersMobBtn" onClick={() => clearAllFilter()}>
                                      Clear Filters
                                    </button>
                                  </div>
                                </div>

                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </>
                    <div className="table_top_search mt-4">
                      <input
                        id="leaderboardSearch"
                        type="search"
                        placeholder="Search by Trader name"
                        className="form-control input_search"
                        value={search}
                        onChange={(e) => dispatch(updateSearchName(e.target.value))}
                      />
                      <div className="icon_search">
                        <FontAwesomeIcon icon={faSearch} size="lg" className="searchicon" />
                      </div>
                    </div>
                    <div className="table_top_search filter mt-4 d-none d-sm-flex">
                      <div>
                        <button className="filtersBtn" onClick={() => activateAllFilter()}>
                          {isFiltersActivated ? (
                            <>
                              <img src={crossIcon} alt="" className="" style={{ height: '32%' }} />
                            </>
                          ) : (
                            <>
                              <img src={filterIcon} alt="" className="" style={{ height: '32%' }} />
                            </>
                          )} Filters
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <>
                  <div className="extendedFilters mt-1 row">
                    <div className="col-md-4 col-12">
                      <div className="extendedFilters mt-1">
                        <label className="form-check-label" for="flexSwitchCheckDefault">Only show profitable traders</label>
                        <div className="form-check form-switch">
                          <input className="form-check-input form-check-input-customSwitch" type="checkbox" id="flexSwitchCheckDefault" checked={onlyProfitableFilter} onChange={(e) => applyProfitableTradeFilter(e.target.checked)} />
                        </div>
                      </div>
                    </div>
                    {isFiltersActivated && (
                      <div className="col-md-8 col-12 d-none d-sm-flex table_top" style={{ display: 'flex', justifyContent: 'end' }}>

                        <div className="table_top_a row filtersDiv m-0 justify-content-end" style={{ gap: '19px' }}>
                          <div className="table_top_sort col-md m-0 p-0">
                            <label htmlFor="statsType" className="label_sort">
                              Tracking Time
                            </label>
                            <FilterSelector name={'trackingTime'} options={trackingTimeOptions} selectedValue={trackingDaysFilter}
                              onChange={(e) => handleTrackingTimeChange(e.target.value)} />
                          </div>

                          <div className="table_top_sort col-md m-0 p-0">
                            <label htmlFor="statsType" className="label_sort">
                              Trades (last 7 days)
                            </label>
                            <FilterInputSelector name={'noOfTrades'} options={noOfTradesLast7daysOptions} selectedValue={tradesLastSevenDaysFilter}
                              handleChange={(e) => handleNoOfTradesLast7daysChange(e)} symbol="≥ "/>
                          </div>

                          <div className="table_top_sort col-md m-0 p-0">
                            <label htmlFor="statsType" className="label_sort">
                              Win rate
                            </label>
                            <FilterSelector name={'winRate'} options={winRateOptions} selectedValue={winRateFilter}
                              onChange={(e) => handleWinRateChange(e.target.value)} />
                          </div>

                          <div className="table_top_sort col-md m-0 p-0">
                            <label htmlFor="statsType" className="label_sort">
                              PNL
                            </label>
                            <FilterInputSelector name={'PNL'} options={pnlHigherThanOptions} selectedValue={pnlHigherThanFilter}
                              handleChange={(e) => handlePnlHigherThanChange(e)} symbol="≥ $"/>
                          </div>

                          <div className="col-md m-0 p-0 clearFilter table_top_sort">
                            <label className="label_sort"></label>
                            <div style={{ height: '100%', alignSelf: 'flex-start' }}>
                              <button className="clearFiltersBtn" style={{ width: '109px' }} onClick={() => clearAllFilter()}>
                                Clear Filters
                              </button>
                            </div>
                          </div>

                        </div>
                      </div>
                    )}
                  </div>
                </>

                <div className="media_table">
                  <div className="table_res">
                    <table cellSpacing="10" cellPadding="0" class="table table-hover media_table">
                      <thead>
                        <tr>
                          <th className="head_sno" style={{ width: "1%" }}>#</th>
                          {columns.map((col, index) => (
                            <th key={index} className={col.class}>
                              <button
                                type="button"
                                onClick={() => handleSort(col)}>
                                {col.title}
                                {sortType === col.sortKey && (
                                  sortDirection === "asc"
                                    ? <FontAwesomeIcon icon={faSortUp} className="leaderboard-sort-icon" />
                                    : <FontAwesomeIcon icon={faSortDown} className="leaderboard-sort-icon" />
                                )}
                              </button>
                            </th>
                          ))}
                          <th>Action</th>
                        </tr>
                      </thead>
                      {isLoadingProduct ?
                        <div class="table_loader"> <Loader /> </div>
                        :
                        <tbody className="ctt">
                          {sortedItems
                            .map((item, index) => {
                              if (item.openPositions == 0) {
                                item.openPositions = "0";
                              }

                              return (
                                <tr key={index} className="sub-body">
                                  <td className="body_sno">
                                    {item.rowNo}
                                  </td>

                                  <td className="body_trader" style={{ padding: '0' }}>
                                    <Link to={`/profile/${item.id}`} className="anchorBlockClickable">
                                      {item.userPhotoUrl !== "" && validateUrl(item.userPhotoUrl) ? (
                                        <img
                                          src={item.userPhotoUrl}
                                          alt=""
                                          className="trader_img"
                                        />
                                      ) : (
                                        <img src={dummy} alt="" className="trader_img" />
                                      )}
                                      <span>{item.nickName}</span>
                                    </Link>
                                  </td>

                                  <td className="body_profitloss" style={{ padding: '0' }}>
                                    <Link to={`/profile/${item.id}`} className="anchorBlockClickable">
                                      <NumericIndicators percentage={item.dailyROI} amount={item.dailyPNL} />
                                    </Link>
                                  </td>

                                  <td className="body_trades" style={{ padding: '0' }}>
                                    <Link to={`/profile/${item.id}`} className="anchorBlockClickable">
                                      <NumericIndicators percentage={item.weeklyROI} amount={item.weeklyPNL} />
                                    </Link>
                                  </td>

                                  <td className="body_traderequity color_red" style={{ padding: '0' }}>
                                    <Link to={`/profile/${item.id}`} className="anchorBlockClickable">
                                      <NumericIndicators percentage={item.monthlyROI} amount={item.monthlyPNL} />
                                    </Link>
                                  </td>

                                  <td className="body_followerequity" style={{ padding: '0' }}>
                                    <Link to={`/profile/${item.id}`} className="anchorBlockClickable">
                                      <NumericIndicators percentage={item.allROI} amount={item.allPNL} />
                                    </Link>
                                  </td>

                                  <td className="body_follower" style={{ padding: '0' }}>
                                    <Link to={`/profile/${item.id}`} className="anchorBlockClickable">
                                      {item.followers
                                        ? `${item.followers.toLocaleString("en-US", {
                                          valute: "USD",
                                        })}`
                                        : 0}
                                    </Link>
                                  </td>

                                  <td className="body_openposition" style={{ padding: '0' }}>
                                    <Link to={`/profile/${item.id}`} className="anchorBlockClickable">
                                      {item.openPositions ? item.openPositions : ""}
                                    </Link>
                                  </td>
                                  <td className="body_follow">
                                    {selectedProfileType === 'Favorite' ? (
                                      <>
                                        <input
                                          type="button"
                                          value={sortedItems?.filter(m => m.isFavorite && m.id == item.id).length > 0 ? "Remove Favorite" : "Favorite"}
                                          className="btn btn-primary follow"
                                          onClick={(e) => toggleFavorite(item.id, e)}
                                        />
                                      </>
                                    ) : (
                                      <>
                                        {!isFreeSubscriptionExpired ? (
                                          <>
                                            {!hasFreeSubscription && !hasSubscription ? (
                                              <input
                                                type="button"
                                                value={item.isFollowed ? "- Unfollow" : "Follow"}
                                                className="btn btn-primary follow"
                                                data-bs-toggle="modal" data-bs-target="#exampleModal"
                                              />
                                            ) : (
                                              <>
                                                {!userInfo?.telegramChatId ? (
                                                  <>
                                                    <input
                                                      type="button"
                                                      value={item.isFollowed ? "- Unfollow" : "Follow"}
                                                      className="btn btn-primary follow"
                                                      onClick={() => openConnectTelegramModal()}
                                                    />
                                                  </>
                                                ) : (
                                                  <>
                                                    <input
                                                      type="button"
                                                      value={item.isFollowed ? "- Unfollow" : "Follow"}
                                                      className="btn btn-primary follow"
                                                      onClick={(e) => { toggleFollower(item.id, e); }
                                                      } />
                                                  </>
                                                )}
                                              </>
                                            )}
                                          </>
                                        ) : (
                                          <>
                                            {!hasSubscription ? (
                                              <input
                                                type="button"
                                                value={item.isFollowed ? "- Unfollow" : "Follow"}
                                                className="btn btn-primary follow"
                                                data-bs-toggle="modal" data-bs-target="#exampleModal"
                                              />
                                            ) : (
                                              <>
                                                {!userInfo?.telegramChatId ? (
                                                  <>
                                                    <input
                                                      type="button"
                                                      value={item.isFollowed ? "- Unfollow" : "Follow"}
                                                      className="btn btn-primary follow"
                                                      onClick={() => openConnectTelegramModal()}
                                                    />
                                                  </>
                                                ) : (
                                                  <>
                                                    <input
                                                      type="button"
                                                      value={item.isFollowed ? "- Unfollow" : "Follow"}
                                                      className="btn btn-primary follow"
                                                      onClick={(e) => { toggleFollower(item.id, e); }
                                                      } />
                                                  </>
                                                )}
                                              </>
                                            )}
                                          </>
                                        )}

                                      </>
                                    )}
                                  </td>
                                  {/* <Desbtn/> */}
                                </tr>
                              );
                            })}
                        </tbody>
                      }
                    </table>
                    {leaderboardUpdateTime && (
                      <>
                        <div style={{ color: 'gray', paddingBottom: '5px' }}>
                          {leaderboardUpdateTime ? `Last updated: ${formatDate(new Date(leaderboardUpdateTime))}` : 'Loading...'}
                        </div>
                      </>
                    )}
                  </div>

                  <PaginationRow
                    currentPage={currentPage}
                    rowsPerPage={rowsPerPage}
                    totalItems={totalItems}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                  />
                </div>
                {/* media  */}
                <div className="media_Body">
                  {sortedItems
                    .map((item, index) => {
                      return (
                        <>
                          <div className="flex_body">
                            <div key={index} className="media_content">
                              <Link to={`/profile/${item.id}`}>
                                <div className="firstLine">
                                  <div className="line_a">
                                    {item.userPhotoUrl !== "" && validateUrl(item.userPhotoUrl) ? (
                                      <img
                                        src={item.userPhotoUrl}
                                        alt=""
                                        className="trader_img"
                                      />
                                    ) : (
                                      <img src={dummy} alt="" className="trader_img" />
                                    )}
                                    <span>{item.nickName}</span>
                                  </div>
                                  <div className="firstline_a">
                                    <h6>
                                      Follower
                                    </h6>
                                    {item.followers && item.followers ? (
                                      <p style={{ textAlign: 'center' }}>
                                        {item.followers.toLocaleString("en-US", {
                                          valute: "USD",
                                        })}
                                      </p>
                                    ) : (
                                      <p>0</p>
                                    )}
                                  </div>
                                  <div className="firstline_b">
                                    <h6>
                                      Open Position
                                    </h6>
                                    <p style={{ textAlign: 'center' }}>
                                      {" "}
                                      {item.openPositions ? item.openPositions : ""}
                                    </p>
                                  </div>
                                  <Mobbtn
                                    isFollow={sortedItems?.filter(m => m.isFollowed && m.id == item.id).length > 0}
                                    isFavorite={sortedItems?.filter(m => m.isFavorite && m.id == item.id).length > 0}
                                    leaderId={item.id}
                                    toggleFollower={toggleFollower}
                                    toggleFavorite={toggleFavorite}
                                    hasSubscription={hasSubscription}
                                    showStripeModal={showStripeModal}
                                    activeBtn={selectedProfileType}
                                    isFreeSubscriptionExpired={isFreeSubscriptionExpired}
                                    hasFreeSubscription={hasFreeSubscription}
                                    hasUserTGChatId={hasUserTGChatId}
                                    openConnectTelegramModal={openConnectTelegramModal}
                                    closeConnectTelegramModal={closeConnectTelegramModal}
                                  />

                                </div>
                                <div className="secondline">
                                  <div className="row">
                                    <div className="secondline_a col-6">
                                      <h5>
                                        Daily
                                      </h5>
                                      <div>
                                        <NumericIndicators percentage={item.dailyROI} amount={item.dailyPNL} />
                                      </div>
                                    </div>
                                    <div className="secondline_b col-6">
                                      <h5>
                                        Weekly
                                      </h5>
                                      <div>
                                        <NumericIndicators percentage={item.weeklyROI} amount={item.weeklyPNL} />
                                      </div>
                                    </div>
                                    <div className="secondline_c col-6 mt-2">
                                      <h5>
                                        Monthly
                                      </h5>
                                      <div>
                                        <NumericIndicators percentage={item.monthlyROI} amount={item.monthlyPNL} />
                                      </div>
                                    </div>
                                    <div className="secondline_d col-6 mt-2">
                                      <h5>
                                        All Time
                                      </h5>
                                      <div>
                                        <NumericIndicators percentage={item.allROI} amount={item.allPNL} />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            </div>
                          </div>
                        </>
                      );
                    })}

                  <PaginationRow
                    currentPage={currentPage}
                    rowsPerPage={rowsPerPage}
                    totalItems={totalItems}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                  />

                </div>
              </div>
            </div>

            <div class="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div class="modal-dialog" style={{ marginRight: "0", display: 'none' }}>
              </div>
              <div class="modal-body">
                <Pricing />
              </div>
            </div>

            <InformationMessageModal
              isInfoModalOpen={isInfoModalOpen}
              closeInfoModal={closeInfoModal}
              headingContent={'Warning'}
              bodyContent={'You reached the limit of following 10 Traders at the same time. Please unfollow a trader, to follow a new one.'}
            />

            {isWelcomeModalOpen && (
              <>
                <div className={`${styles.overlay}`}></div>
                <div className={`${styles.modal}`} style={{ color: 'white', textAlign: 'left', transform: 'translateX(-46%) translateY(-45%) scale(90%)', transformOrigin: 'top left' }}>
                  <main className={`${styles.Telemodal__main}`}>
                    <br></br>
                    <h2>Welcome to Tycoon Signals!</h2>
                    <i
                      class="fa fa-times"
                      aria-hidden="true"
                      role="button"
                      onClick={closeWelcomeModal}
                      style={{ cursor: 'pointer' }}
                    ></i>
                    <p style={{ color: "#F3BA2F", fontSize: 'large' }} className="pt-3 pb-3">Your 7-day free trial of Tycoon Signals Premium has just started. Browse and follow thousands of traders and follow their strategies!</p>
                    <div>
                      <div>Here are your next steps to take full advantage of Premium:</div>
                      <br></br>
                      <ol>
                        <li>Go to your settings and connect your account to Telegram with just one click 🔔</li>
                        <li>Search the leaderboard for your favourite traders 🔎</li>
                        <li>Follow traders to receive their trading signals in real-time 👀</li>
                        <li>Trade on any exchange of your choice and enjoy the profits 💸</li>
                        <li>Join our Signals discussion group to post your results or discuss strategies with other Traders 💬</li>
                      </ol>
                    </div>
                    <div className={`${styles.settingrighttext}`}>
                      <div>
                        <button onClick={() => window.open("https://t.me/+1kZ9Z4304aI4Y2Yy", '_blank')}>
                          Join Discussion Group
                        </button>
                      </div>
                    </div>
                    <div style={{ color: 'white', textAlign: 'left', width: '92%' }} className="pt-4 pb-2">
                      <span>Need some help? Below is a short tutorial on how to use Tycoon Signals </span>
                      <a href="https://youtu.be/itkEC0Q3z0A" target="_blank" className="text-decoration-none" style={{ color: '#ffba00' }}>Check Tutorial</a>
                    </div>
                    <div style={{ color: 'white', textAlign: 'left', width: '92%' }} className="pt-3 pb-3">
                      We hope you will take full advantage of your 7-day trial and don’t forget to choose a premium plan that suits your needs, to continue using Tycoon Signals after the 7 days.
                    </div>
                    <div style={{ color: 'white', textAlign: 'left', width: '92%' }} className="pt-2 pb-3">
                      Happy profits 🤝
                    </div>
                  </main>
                </div>
              </>
            )}

            {isConnectTelegramModalOpen && (
              <>
                <ConnectTelegramModal
                  isOpen={isConnectTelegramModalOpen}
                  closeConnectTelegramModal={closeConnectTelegramModal}
                  onTelegramLinkClicked={onTelegramLinkClicked}
                  userInfo={userInfo}
                  botNameRefer={botNameRefer}
                  isBotNameCopied={isBotNameCopied}
                  copyTGBotnameToClipboard={copyTGBotnameToClipboard}
                  refer={refer}
                  isSecretCopied={isSecretCopied}
                  copyToClipboard={copyToClipboard}
                />
              </>
            )}
          </>
        ) : (
          ""
        )
      }


    </>
  );
};

const NumericIndicators = ({ percentage, amount }) => {
  return (
    <>
      <h6 className={percentage < 0 ? "color_red" : ""}>
        <Percentage value={percentage} />
      </h6>
      <p>≈ <Currency amount={amount} /></p>
    </>
  );
}


const StatsPeriodSelector = ({ periodType, statsPeriodChanged }) => {
  const timeOptions = [
    { value: "daily", label: "Daily" },
    { value: "weekly", label: "Weekly" },
    { value: "monthly", label: "Monthly" },
    { value: "all", label: "All" },
  ];

  return (
    <select name="time" id="format" value={periodType} onChange={statsPeriodChanged}>
      {timeOptions.map(option => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

const FilterSelector = ({ options, selectedValue, onChange, name }) => {
  return (
    <div className="select">
      <select name={name} value={selectedValue} onChange={onChange}>
        {options.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};


const FilterInputSelector = ({ options, selectedValue, handleChange, symbol }) => {
  const cleanInput = (e) => {
    if (selectedValue === 0) {
      e.target.nextSibling.firstChild.firstChild.firstChild.value = '';
    } else {
      e.target.nextSibling.firstChild.firstChild.firstChild.value = symbol + selectedValue;
    }
  }
  const handleValue = (e) => {
    const numericValue = e.target.value.replace(/[^0-9]/g, '');
    if (numericValue === '') {
      e.target.value = null;
      handleChange(0);
    } else {
      e.target.value = symbol + numericValue;
    }
  } 
  const handleInput = (e) => {
    const input = e.target.value.replace(/[^0-9]/g, '')
    if (input === '') {
      e.target.value = null;
      handleChange(0);
    }
    else {
      handleChange(input);
    }
  }
  return (
    <div className="dropdown customeDropdown">
      <button
        className="btn btn-secondary dropdown-toggle"
        style={{fontSize: "14px", fontFamily: "'Open Sans', sans-serif", fontWeight: "bold", overflow: "hidden"}}
        type="button"
        id="dropdownMenuButton1"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        onClick={(e) => cleanInput(e)}
      >
        {selectedValue === 0 ? ('Select') : symbol + selectedValue}
      </button>
      <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
        <li className="dropDownCustomValue">
          <div className="form-check dropdown-item custom-input-container" style={{minHeight:"1.2em", padding: "0 0.5em 0"}}>
            <input
              className="form-control customDropDownInput"
              style={{minHeight:"2.4em", fontWeight:"normal", padding: "0 0.25em 0", fontSize: "14px", fontFamily: "'Open Sans', sans-serif"}}
              type="text"
              placeholder={symbol + 'Enter Value'}
              onChange={(e) => {
                handleValue(e);
              }}
              onKeyUp={(e) => {
                if (e.key === 'Enter') {
                  handleInput(e);
                }
              }}
            />
          </div>
        </li>
        {options.map((option) => (
          <li key={option.value} style={{ textAlign: 'left' }} onClick={() => handleChange(option.value)}>
            <div className="form-check dropdown-item" style={{minHeight:"1.2em", fontWeight:"normal", padding: "0 0.75em 0", fontSize: "14px", fontFamily: "'Open Sans', sans-serif"}}>
              <label className="form-check-label" >
                {option.label}
              </label>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

    {/* <div className="select">
      
      <select name={name} value={selectedValue} onChange={onChange}>
        {options.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div> */}

const CustomDropdown = ({
  options,
  onSelectionChange,
  title,
  customInputShow,
  fromPNL,
  resetCustomNumber,
  selectedValues,
  customSelectedNumber
}) => {
  const [selectedOption, setSelectedOption] = useState('');
  const [customNumber, setCustomNumber] = useState('');

  useEffect(() => {
    setCustomNumber(customSelectedNumber || '');
  }, [customSelectedNumber]);

  useEffect(() => {
    setSelectedOption(selectedValues || '');
  }, [selectedValues]);

  const handleOptionChange = (option) => {
    if (selectedOption === option) {
      setSelectedOption('');
      onSelectionChange('');
    } else {
      setSelectedOption(option);
      setCustomNumber('');
      onSelectionChange(option);
    }
  };

  const handleCustomNumberChange = (e) => {
    const value = e.target.value;
    if (value === '' || (/^[1-9]\d*$/).test(value)) {
      setCustomNumber(value);
      setSelectedOption('');
      onSelectionChange({ custom: value });
    } else if (value === '-' || !(/^\d+$/).test(value)) {
      setCustomNumber('');
    }
  };

  useEffect(() => {
    if (resetCustomNumber) {
      setCustomNumber('');
      setSelectedOption('');
    }
  }, [resetCustomNumber]);

  return (
    <div className="dropdown customeDropdown">
      <button
        className="btn btn-secondary dropdown-toggle"
        type="button"
        id="dropdownMenuButton1"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        {title}
      </button>
      <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
        {customInputShow && (
          <li className="dropDownCustomValue">
            <div className="form-check dropdown-item custom-input-container">
              <input
                className="form-control customDropDownInput"
                type="text"
                value={customNumber}
                onChange={handleCustomNumberChange}
              />
              {fromPNL && <span className="input-label-for-pnl">K</span>}
            </div>
          </li>
        )}
        {options.map((option, index) => (
          <li key={index} style={{ textAlign: 'center' }}>
            <div className="form-check dropdown-item">
              <input
                className="form-check-input form-checkbox-custom mt-0"
                type="checkbox"
                id={`custom-check-${index}`}
                onChange={() => handleOptionChange(option)}
                checked={selectedOption === option}
              />
              <label className="form-check-label" htmlFor={`custom-check-${index}`}>
                {option}
              </label>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};


