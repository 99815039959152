import React from 'react';

const formatCurrency = (amount = 0, currency = 'USD') => {
  const currencySymbols = {
    USD: '$',
    EUR: '€',
    GBP: '£',
  };

  const roundedAmount = Math.round(amount * 100) / 100;
  const isNegative = roundedAmount < 0;
  const absAmount = Math.abs(roundedAmount);
  const symbol = currencySymbols[currency] || '';

  const formattedAmount = `${symbol}${absAmount
    .toFixed(2)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;

  return isNegative ? `-${formattedAmount}` : formattedAmount;
};

const Currency = ({ amount, currency = 'USD' }) => {
  return <span>{formatCurrency(amount, currency)}</span>;
};

const Percentage = ({ value }) => {
  const displayValue = value === null || value === undefined ? 0 : value / 100;
  const formattedValue = new Intl.NumberFormat(undefined, {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(displayValue);

  const displayString = (value > 0 ? '+' : '') + formattedValue;

  return <span>{displayString}</span>;
};

export { Currency, Percentage };
