export const UPDATE_PROFILE_TYPE = 'UPDATE_PROFILE_TYPE'
export const UPDATE_STATS_TYPE = 'UPDATE_STATS_TYPE'
export const UPDATE_PERIOD_TYPE = 'UPDATE_PERIOD_TYPE'
export const UPDATE_SORT_TYPE = 'UPDATE_SORT_TYPE'
export const UPDATE_SORT_DIRECTION = 'UPDATE_SORT_DIRECTION'
export const UPDATE_SEARCH_NAME = 'UPDATE_SEARCH_NAME'
export const UPDATE_SUBSCRIPTION_FLAG = 'UPDATE_SUBSCRIPTION_FLAG'
export const UPDATE_USER = 'UPDATE_USER'
export const UPDATE_CURRENT_PAGE = 'UPDATE_CURRENT_PAGE'
export const UPDATE_ROWS_PER_PAGE = 'UPDATE_ROWS_PER_PAGE'
export const UPDATE_TOTAL_ITEMS = 'UPDATE_TOTAL_ITEMS'
export const UPDATE_SKIP_RECORDS = 'UPDATE_SKIP_RECORDS'
export const UPDATE_PREVIOUS_PROFILE_TYPE = 'UPDATE_PREVIOUS_PROFILE_TYPE'

//Filters Constants
export const UPDATE_ONLY_PROFITABLE_FILTER = 'UPDATE_ONLY_PROFITABLE_FILTER'
export const UPDATE_TRACKING_DAYS_FILTER = 'UPDATE_TRACKING_DAYS_FILTER'
export const UPDATE_PNL_HIGHER_THAN_FILTER = 'UPDATE_PNL_HIGHER_THAN_FILTER'
export const UPDATE_TRADES_LAST_SEVEN_DAYS_FILTER = 'UPDATE_TRADES_LAST_SEVEN_DAYS_FILTER'
export const UPDATE_WIN_RATE_FILTER = 'UPDATE_WIN_RATE_FILTER'
export const UPDATE_IS_FILTERS_ACTIVATED = 'UPDATE_IS_FILTERS_ACTIVATED'
export const UPDATE_RESET_CUSTOM_NUMBER_FLAG = 'UPDATE_RESET_CUSTOM_NUMBER_FLAG'