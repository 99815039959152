import React, { useEffect, useRef, useState, Fragment } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";

import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../Redux/leaderboardAction";

import styles from "../css/setting.module.css";

import { Footer } from "./Footer";
import { Header } from "./Header";
import MobHeader from "./MobHeader";
import Loader from './Loader';

import telegram from "../Components/Images/userVector (1).svg";
import tooltipinfo from "../Components/Images/infobox.svg";
import tele from "../Components/Images/icontele.svg";
import copyicon from "../Components/Images/copy.svg";
import CrossIcon from "../Components/Images/CrossIcon.svg"

export const Setting = () => {
  const [hasSubscription, setHasSubscription] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [customerId, setCustomerId] = useState("");
  const [leaderId, setLeaderId] = useState();
  const [webhookURL, setWebhookURL] = useState();
  const [userLeaderId, setUserLeaderId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [infoMessage, setInfoMessage] = useState(null);
  const [isConnectTelegramModalOpen, setIsConnectTelegramModalOpen] = useState(false);
  const [isRemoveConnectTelegramModalOpen, setIsRemoveConnectTelegramModalOpen] = useState(false);
  const [isConnectWebhookModalOpen, setIsConnectWebhookModalOpen] = useState(false);
  const [isRemoveWebhookModalOpen, setIsRemoveWebhookModalOpen] = useState(false);
  const [isSecretCopied, setIsSecretCopied] = useState(false);
  const [isBotNameCopied, setIsBotNameCopied] = useState(false);
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [resetPasswordMessage, setResetPasswordMessage] = useState("");
  const [hasFreeSubscription, setHasFreeSubscription] = useState(false);
  const [isFreeSubscriptionExpired, setIsFreeSubscriptionExpired] = useState(false);
  const [webhookUrlErrorMessage, setWebhookUrlErrorMessage] = useState("");

  const userRecord = useSelector(state => state.user);
  const dispatch = useDispatch();

  const auth0Config = window._env_.REACT_APP_AUTH_CONFIG;
  const domain = auth0Config.domain;
  const clientId = auth0Config.clientId;
  const dbConnection = auth0Config.dbConnection;

  const telegramBotLink = window._env_.REACT_APP_TELEGRAM_BOT_LINK;
  const {
    user,
    isAuthenticated,
    getAccessTokenSilently
  } = useAuth0();
  const navigate = useNavigate();

  const [hasTrialSubscription, setHasTrialSubscription] = useState(false);

  const freeSubscriptionConfig = window._env_.REACT_APP_FREE_SUBSCRIPTION_OPTIONS;
  const freeSubscriptionEnabled = freeSubscriptionConfig.freeSubscriptionEnabled;
  const freeSubscriptionStart = new Date(freeSubscriptionConfig.freeSubscriptionStart);
  const freeSubscriptionEnd = new Date(freeSubscriptionConfig.freeSubscriptionEnd);

  useEffect(() => {
    const currentDate = new Date();

    if (freeSubscriptionEnabled && currentDate >= freeSubscriptionStart && currentDate <= freeSubscriptionEnd) {
      setIsFreeSubscriptionExpired(false);
    } else {
      setIsFreeSubscriptionExpired(true);
    }

    if (leaderId === "") {
      setErrorMessage("Please enter your binance encrypted UID!");
    } else {
      setErrorMessage("");
    }
  }, []);

  const openChangePasswordModal = () => {
    changePassword();
  };

  const openConnectTelegramModal = () => {
    setIsSecretCopied()
    setIsBotNameCopied()
    setIsConnectTelegramModalOpen(true);
  };

  const openRemoveConnectTelegramModal = () => {
    setIsRemoveConnectTelegramModalOpen(true);
  };

  const closeConnectTelegramModal = () => {
    setIsConnectTelegramModalOpen(false);
  };

  const closeRemoveConnectTelegramModal = () => {
    setIsRemoveConnectTelegramModalOpen(false);
  };

  const openConnectWebhookModal = () => {
    setIsConnectWebhookModalOpen(true);
  };

  const closeConnectWebhookModal = () => {
    setIsConnectWebhookModalOpen(false);
  };

  const openRemoveWebhookModal = () => {
    setIsRemoveWebhookModalOpen(true);
  };

  const closeRemoveWebhookModal = () => {
    setIsRemoveWebhookModalOpen(false);
  };

  useEffect(() => {
    let trialSubscriptionCondition = false;
    // Get the current date
    const currentDate = new Date();
    const trialEndDate = new Date(userRecord?.trialPeriod?.trialEnd);
    if (currentDate <= trialEndDate && userRecord.subscription === null && !userRecord.lifeTimePlanStripe) {
      trialSubscriptionCondition = true;
      setHasTrialSubscription(true);
    }

    userRecord && (userRecord.subscription !== null || userRecord.lifeTimePlanStripe || trialSubscriptionCondition) ? setHasSubscription(true) : setHasSubscription(false)
    userRecord && (userRecord.subscription === null || !userRecord.lifeTimePlanStripe) && (userRecord?.freeSubscription?.isFreeSubscription) ? setHasFreeSubscription(true) : setHasFreeSubscription(false)
    setUserInfo(userRecord);
    setUserLeaderId(userRecord?.ownLeaderId);
    setLeaderId(userRecord?.ownLeaderId);
    setUserEmail(userRecord?.email);
    setCustomerId(userRecord?.lifeTimePlanStripe ? userRecord?.lifeTimeStripeCustomerId : userRecord?.subscription?.customerId);
    setWebhookURL(userRecord?.webhookURL);

  }, [userRecord])

  const addLeaderApi = async (leaderId) => {
    try {
      setErrorMessage(null);
      setInfoMessage("We are fetching your profile record. Please check your Profile on top right 'User' options after few minutes!");

      const token = await getAccessTokenSilently();
      const response = await axios.post(
        `${window._env_.REACT_APP_API_URL}/Users/YourLeaderProfile`,
        `"${leaderId}"`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setIsLoading(false);
        if (response.data.message) {
          setErrorMessage(response.data.message);
        }
      }
      else {
        setInfoMessage(null);
        setErrorMessage("An error occurred while adding the leader. Please try again later.");
      }
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const addLeaderProfileAndNavigate = async () => {
    if (leaderId !== null && leaderId !== undefined && leaderId !== "") {
      setIsLoading(true);
      const encryptedUIDPattern = /^[0-9A-Z]{32}$/;
      if (encryptedUIDPattern.test(leaderId)) {
        await addLeaderApi(leaderId);
      }
      else {
        setIsLoading(false);
        setErrorMessage("Your encrypted UID is incorrect please check tutorial again!")
      }
    }
    else {
      setIsLoading(false);
      setErrorMessage("Please enter your binance encrypted UID!")
    }
  }

  const addUserWebhookUrlApi = async (url) => {
    try {
      setWebhookUrlErrorMessage("");
      const token = await getAccessTokenSilently();
      const response = await axios.post(
        `${window._env_.REACT_APP_API_URL}/Users/AddWebhookURL`,
        `"${url}"`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setIsLoading(false);
        if (response.data.message) {
          setWebhookUrlErrorMessage(response.data.message);
        } else {
          setUserInfo(response.data);
          closeConnectWebhookModal();
        }
      }
      else {
        setWebhookUrlErrorMessage("An error occurred while adding the webhook url. Please try again later.");
      }
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const addUserWebhookUrl = async () => {
    if (!webhookURL) {
      setIsLoading(false);
      setWebhookUrlErrorMessage("Please enter your webhook URL!");
      return;
    }
    setIsLoading(true);
    const urlPattern = new RegExp('^(https?:\\/\\/)' +
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
      '((\\d{1,3}\\.){3}\\d{1,3}))' +
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
      '(\\?[;&a-z\\d%_.~+=-]*)?' +
      '(\\#[-a-z\\d_]*)?$', 'i');

    if (urlPattern.test(webhookURL)) {
      await addUserWebhookUrlApi(webhookURL);
    } else {
      setIsLoading(false);
      setWebhookUrlErrorMessage("Invalid URL format. Please enter a valid webhook URL!");
    }
  }


  const refer = useRef(null);
  const botNameRefer = useRef(null);

  const copyToClipboard = async (e) => {
    refer.current.select();
    await navigator.clipboard.writeText(refer.current.value);
    setIsSecretCopied(true)
  };

  const copyTGBotnameToClipboard = async (e) => {
    botNameRefer.current.select();
    await navigator.clipboard.writeText(botNameRefer.current.value);
    setIsBotNameCopied(true)
  };

  const getRedirectUriForManageSubscription = async () => {
    if (isAuthenticated) {
      const token = await getAccessTokenSilently();
      axios
        .get(`${window._env_.REACT_APP_API_URL}/Users/GetRedirectURL?customerId=${customerId}`, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          window.location.href = response.data;
        })
        .catch((error) => {
          // Handle the error
          console.log(error);
        });
    }
  };

  const onTelegramLinkClicked = () => {
    let botLinkWithSecret = telegramBotLink + "?start=" + userInfo?.telegramSecret
    window.open(botLinkWithSecret) //to open new page
  }

  const navicateToProfilePage = (id) => {
    if (id !== null && id !== "") {
      navigate(`/profile/${id}`);
    }
  }

  const changePassword = async () => {
    try {
      if (isAuthenticated) {
        let url = `https://${domain}/dbconnections/change_password`;

        await axios
          .post(url, {
            client_id: clientId,
            email: user.email,
            connection: dbConnection
          }, {
            headers: {
              'content-type': 'application/json'
            },
          })
          .then((response) => {
            setResetPasswordMessage(response.data);
            setShowResetPassword(true)
            setIsLoading(false);
          })
          .catch((error) => {
            console.log(error);
          });
      }

    } catch (error) {
      console.error('Error changing password:', error);
    }
  };

  const disconnectTelgramChatId = async () => {
    setIsLoading(true);
    try {
      if (isAuthenticated) {
        const token = await getAccessTokenSilently();
        const response = await axios.get(
          `${window._env_.REACT_APP_API_URL}/Users/RemoveTelegramChatId`,
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200) {
          setIsLoading(false);
          setUserInfo(response.data);
          dispatch(updateUser(response.data));
          closeRemoveConnectTelegramModal();
        }
        setIsLoading(false);
      }
    }
    catch (error) {
      console.error(error);
    }
  }

  const disconnectWebhookUrl = async () => {
    setIsLoading(true);
    try {
      if (isAuthenticated) {
        const token = await getAccessTokenSilently();
        const response = await axios.get(
          `${window._env_.REACT_APP_API_URL}/Users/RemoveWebhookURL`,
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200) {
          setIsLoading(false);
          setUserInfo(response.data);
          dispatch(updateUser(response.data));
        }
        setIsLoading(false);
        closeRemoveWebhookModal();
      }
    }
    catch (error) {
      console.error(error);
    }
  }

  return (
    <>
      <Fragment>
        <div>
          <Header />
          <MobHeader />

          <div className="container-f">
            <div
              className={`loginbtn ${styles.settingback}`}
              style={{ position: "relative", left: "-0%", top: "10px" }}
            >
              <ul>
                <li className={`loginbtn ${styles.backbutton}`}>
                  <Link to="/" style={{ width: "190%!important" }}>
                    {" "}
                    <span>
                      {" "}
                      <em>＜</em> Back to Leaderboard{" "}
                    </span>
                  </Link>
                </li>
              </ul>
            </div>
            <br></br>
            <div className={`${styles.settinghead}`}>Settings</div>
            {isLoading ? (
              <>
                <div className="loaderCenter">
                  <Loader />
                </div>
              </>
            ) : (
              <>

                <div className={`${styles.settingsec}`}>
                  <div className={`${styles.settingleft}`}>
                    <img src={telegram} alt="" srcset="" />
                    <h1>Trading Profile</h1>
                    <h2>
                      To track your own trading results please add here your Binance
                      Encrypted UID. Here you can find in detail how it works. <a href="https://docs.tycoon.io/overview/our-features/track-your-performance#how-to-track-your-own-performance" target="_blank" className="text-decoration-none" style={{ color: '#ffba00' }}>Check Tutorial</a>
                    </h2>
                    {userLeaderId ? (
                      <>
                        <div className={`${styles.settingrighttext}`}>
                          <div>
                            <button onClick={() => navicateToProfilePage(userLeaderId)}>
                              Go to my Profile
                            </button>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <input
                          type="text"
                          value={leaderId}
                          onChange={(e) => {
                            setLeaderId(e.target.value);
                          }}
                        />
                        <div className={`${styles.settingrighttext}`}>
                          <div>
                            <button onClick={() => addLeaderProfileAndNavigate()}>
                              Save Profile
                            </button>
                          </div>
                        </div>
                      </>
                    )}

                    {errorMessage !== "" && errorMessage !== null ? (
                      <>
                        <div className={`${styles.settingrighttext} text-center mt-2`}>
                          <div className="text-danger">
                            {errorMessage}
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className={`${styles.settingrighttext} text-center mt-2`}>
                          <div className="text-success">
                            {infoMessage}
                          </div>
                        </div>
                      </>
                    )}

                  </div>
                  <div className={`${styles.settingright}`}>
                    <div className={`${styles.settingrightemail}`}>
                      <h3>Email</h3>
                      <div>
                        <h1>{userEmail}</h1>
                      </div>
                    </div>
                    <div className={`${styles.settingrightborder}`}></div>
                    <div className={`${styles.settingrighttele}`}>
                      <div className="p-0 m-0">
                        <h3 style={{ display: "flex" }}>
                          Telegram{" "}
                          <div className={`${styles.tooltipico1}`}>
                            {" "}
                            <div className={`${styles.tooltip1}`}>
                              {" "}
                              <img
                                src={tooltipinfo}
                                className={`${styles.imghov}`}
                                alt="Total Profit"
                              />
                              <span className={`${styles.tooltiptext1}`}>
                                To receive trading Signals from followed traders, you need to connect your Tycoon account to Telegram once. If you need a change, please
                                write to support@tycoon.io
                              </span>
                            </div>{" "}
                          </div>
                        </h3>
                        {!isFreeSubscriptionExpired ? (
                          <>
                            {!hasSubscription && !userInfo?.telegramChatId && !hasFreeSubscription &&
                              <h5>
                                Please upgrade your subscription to connect Telegram and
                                receive Trading Signals.
                              </h5>
                            }
                          </>
                        ) : (
                          <>
                            {!hasSubscription && !userInfo?.telegramChatId &&
                              <h5>
                                Please upgrade your subscription to connect Telegram and
                                receive Trading Signals.
                              </h5>
                            }
                          </>
                        )}
                      </div>
                      <div>
                        {!hasSubscription ? (
                          <>
                            {!isFreeSubscriptionExpired ? (
                              <>
                                {!hasFreeSubscription && !userInfo?.telegramChatId && (
                                  <>
                                    <div className={`${styles.connectedBtn}`}>
                                      <button>Connect your Telegram</button>
                                    </div>
                                  </>
                                )}
                                {!hasFreeSubscription && userInfo?.telegramChatId && (
                                  <>
                                    <div className={`${styles.settingrightsubText}`}>
                                      <div>
                                        <h3>Telegram is connected ✅</h3>
                                        <a onClick={openRemoveConnectTelegramModal} style={{ color: '#ffba00', cursor: 'pointer' }}>disconnect</a>
                                      </div>
                                    </div>
                                  </>
                                )}
                                {hasFreeSubscription && (
                                  <>
                                    {userInfo?.telegramChatId ? (
                                      <>
                                        <div className={`${styles.settingrightsubText}`}>
                                          <div>
                                            <h3>Telegram is connected ✅</h3>
                                            <a onClick={openRemoveConnectTelegramModal} style={{ color: '#ffba00', cursor: 'pointer' }}>disconnect</a>
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <div className={`${styles.settingrightsub}`}>
                                          <div>
                                            <button onClick={openConnectTelegramModal}>Connect your Telegram</button>
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  </>
                                )}
                              </>
                            ) : (
                              <>
                                {!userInfo?.telegramChatId && (
                                  <>
                                    <div className={`${styles.connectedBtn}`}>
                                      <button>Connect your Telegram</button>
                                    </div>
                                  </>
                                )}
                                {userInfo?.telegramChatId && (
                                  <>
                                    <div className={`${styles.settingrightsubText}`}>
                                      <div>
                                        <h3>Telegram is connected ✅</h3>
                                        <a onClick={openRemoveConnectTelegramModal} style={{ color: '#ffba00', cursor: 'pointer' }}>disconnect</a>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            {userInfo?.telegramChatId ? (
                              <>
                                <div className={`${styles.settingrightsubText}`}>
                                  <div>
                                    <h3>Telegram is connected ✅</h3>
                                    <a onClick={openRemoveConnectTelegramModal} style={{ color: '#ffba00', cursor: 'pointer' }}>disconnect</a>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className={`${styles.settingrightsub}`}>
                                  <div>
                                    <button onClick={openConnectTelegramModal}>Connect your Telegram</button>
                                  </div>
                                </div>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                    <div className={`${styles.settingrightborder}`}></div>

                    {hasSubscription && (
                      <>
                        {hasTrialSubscription && (
                          <>
                            <div className={`${styles.settingrightsub}`}>
                              <div style={{ width: '46%' }}>
                                <h3>Manage your Subscription</h3>
                                <h3>🎁 You have been upgraded to premium for free for 7 days. Choose a premium plan to keep your premium features active.</h3>
                              </div>
                              <div>
                                <button data-bs-toggle="modal" data-bs-target="#exampleModal">Upgrade Now</button>
                              </div>
                            </div>
                          </>
                        )}
                        {!hasTrialSubscription && (
                          <>
                            <div className={`${styles.settingrightsub}`}>
                              <div>
                                <h3>Manage your Subscription </h3>
                              </div>
                              <div>
                                <button onClick={() => { getRedirectUriForManageSubscription(); }}>Manage Subscription</button>
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    )}
                    {!hasSubscription && (
                      <>
                        {!isFreeSubscriptionExpired ? (
                          <>
                            {hasFreeSubscription && (
                              <>
                                <div className={`${styles.settingrightsub}`}>
                                  <div style={{ width: '46%' }}>
                                    <h3>Manage your Subscription</h3>
                                    <h3>🎁 You have been upgraded to premium for free until 31st July. Choose a premium plan starting from 1st August to keep your premium features active.</h3>
                                  </div>
                                  <div>
                                    <button data-bs-toggle="modal" data-bs-target="#exampleModal">Upgrade Now</button>
                                  </div>
                                </div>
                              </>
                            )}
                            {!hasFreeSubscription && (
                              <>
                                {hasTrialSubscription && (
                                  <>
                                    <div className={`${styles.settingrightsub}`}>
                                      <div style={{ width: '46%' }}>
                                        <h3>Manage your Subscription</h3>
                                        <h3>🎁 You have been upgraded to premium for free for 7 days. Choose a premium plan to keep your premium features active.</h3>
                                      </div>
                                      <div>
                                        <button data-bs-toggle="modal" data-bs-target="#exampleModal">Upgrade Now</button>
                                      </div>
                                    </div>
                                  </>
                                )}
                                {!hasTrialSubscription && (
                                  <>
                                    <div className={`${styles.settingrightsub}`}>
                                      <div>
                                        <h3>Upgrade Now </h3>
                                      </div>
                                      <div>
                                        <button data-bs-toggle="modal" data-bs-target="#exampleModal">Upgrade Now</button>
                                      </div>
                                    </div>
                                  </>
                                )}

                              </>
                            )}
                          </>
                        ) : (
                          <>
                            {hasTrialSubscription && (
                              <>
                                <div className={`${styles.settingrightsub}`}>
                                  <div style={{ width: '46%' }}>
                                    <h3>Manage your Subscription</h3>
                                    <h3>🎁 You have been upgraded to premium for free for 7 days. Choose a premium plan to keep your premium features active.</h3>
                                  </div>
                                  <div>
                                    <button data-bs-toggle="modal" data-bs-target="#exampleModal">Upgrade Now</button>
                                  </div>
                                </div>
                              </>
                            )}
                            {!hasTrialSubscription && (
                              <>
                                <div className={`${styles.settingrightsub}`}>
                                  <div>
                                    <h3>Upgrade Now </h3>
                                  </div>
                                  <div>
                                    <button data-bs-toggle="modal" data-bs-target="#exampleModal">Upgrade Now</button>
                                  </div>
                                </div>
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}

                    <div className={`${styles.settingrightborder}`}></div>

                    {/* Webhook URL */}
                    <div className={`${styles.settingrighttele}`}>
                      <div className="p-0 m-0">
                        <h3 style={{ display: "flex" }}>
                          Webhook{" "}                       
                          <div className={`${styles.tooltipico1}`}>
                            {" "}
                            <div className={`${styles.tooltip1}`}>
                              {" "}
                              <img
                                src={tooltipinfo}
                                className={`${styles.imghov}`}
                                alt="Total Profit"
                              />
                              <span className={`${styles.tooltiptext1}`}>
                                To receive trading Signals from followed traders, you need to configure a Webhook once.
                              </span>
                            </div>{" "}
                          </div>
                        </h3>
                        {/* {userInfo?.webhookURL? (
                            <>
                              <h3>URL is integrated ✅</h3>
                            </>
                          ) : ( 
                          <>
                            <h3>No webhook is integrated</h3>
                          </>
                          )}    */}
                        {!isFreeSubscriptionExpired ? (
                          <>
                            {!hasSubscription && !userInfo?.webhookURL && !hasFreeSubscription &&
                              <h5>
                                Please upgrade your subscription to connect Webhook and
                                receive Trading Signals.
                              </h5>
                            }
                          </>
                        ) : (
                          <>
                            {!hasSubscription && !userInfo?.webhookURL &&
                              <h5>
                                Please upgrade your subscription to connect Webhook and
                                receive Trading Signals.
                              </h5>
                            }
                          </>
                        )}
                      </div>
                      <div>
                        {!hasSubscription ? (
                          <>
                            {!isFreeSubscriptionExpired ? (
                              <>
                                {!hasFreeSubscription && userInfo?.webhookURL && (
                                  <>
                                    <div className={`${styles.connectedBtn}`}>
                                      <button>Add</button>
                                    </div>
                                  </>
                                )}
                                {!hasFreeSubscription && !userInfo?.webhookURL && (
                                  <>
                                    <div className={`${styles.settingrightsubText}`}>
                                      <div>
                                        <h3>Webhook is connected ✅</h3>
                                        <a onClick={openRemoveWebhookModal} style={{ color: '#ffba00', cursor: 'pointer' }}>disconnect</a>
                                      </div>
                                    </div>
                                  </>
                                )}
                                {hasFreeSubscription && (
                                  <>
                                    {userInfo?.webhookURL ? (
                                      <>
                                        <div className={`${styles.settingrightsubText}`}>
                                          <div>
                                            <h3>Webhook is connected ✅</h3>
                                            <a onClick={openRemoveWebhookModal} style={{ color: '#ffba00', cursor: 'pointer' }}>disconnect</a>
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <div className={`${styles.settingrightsub}`}>
                                          <div className={`${styles.connectedBtn}`}>
                                            <button onClick={openConnectWebhookModal}>Add</button>
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  </>
                                )}
                              </>
                            ) : (
                              <>
                                {!userInfo?.webhookURL && (
                                  <>
                                    <div className={`${styles.connectedBtn}`}>
                                      <button>Add</button>
                                    </div>
                                  </>
                                )}
                                {userInfo?.webhookURL && (
                                  <>
                                    <div className={`${styles.settingrightsubText}`}>
                                      <div>
                                        <h3>Webhook is connected ✅</h3>
                                        <a onClick={openRemoveWebhookModal} style={{ color: '#ffba00', cursor: 'pointer' }}>disconnect</a>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            {userInfo?.webhookURL ? (
                              <>
                                <div className={`${styles.settingrightcp}`} style={{ paddingTop: "0px",  display: "flex", gap: "2vw" }}>
                                  <div >
                                    <button style={{ width: "11vw" }} onClick={openConnectWebhookModal}>Edit</button>
                                  </div>
                                  <div >
                                    <button style={{ width: "11vw" }} onClick={openRemoveWebhookModal}>Remove</button>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className={`${styles.settingrightsub}`}>
                                  <div>
                                    <button onClick={openConnectWebhookModal}>Add</button>
                                  </div>
                                </div>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </div>

                    <div className={`${styles.settingrightborder}`}></div>

                    <div className={`${styles.settingrightcp}`}>
                      <div>
                        <h3>Change password</h3>
                      </div>
                      <div>
                        <button onClick={openChangePasswordModal}>Change password</button>
                      </div>
                    </div>
                    <div style={{ padding: "10px 0 0 0" }} className={`${styles.settingrightcp}`}>
                      <div>
                      </div>
                      <div>
                        {showResetPassword && (
                          <>
                            <h3 style={{ display: "inline", marginRight: "10px" }}>{resetPasswordMessage}</h3>
                            <img onClick={() => setShowResetPassword(false)} style={{ width: "10px", cursor: 'pointer' }} src={CrossIcon} alt="SVG Image" />
                          </>
                        )}

                      </div>
                    </div>
                  </div>
                </div>
                {isConnectTelegramModalOpen && (
                  <>
                    <div className={`${styles.overlay}`}></div>
                    <div className={`${styles.modal}`}>
                      <main className={`${styles.Telemodal__main}`}>
                        <h2>Connect your Telegram</h2>
                        <i
                          class="fa fa-times"
                          aria-hidden="true"
                          role="button"
                          onClick={closeConnectTelegramModal}
                          style={{ cursor: 'pointer' }}
                        ></i>

                        <p>
                          {" "}
                          Connect your TG account to Tycoon by clicking this Link:{" "}
                        </p>
                        <button onClick={onTelegramLinkClicked}>
                          <img src={tele} alt="Telegram" /> {userInfo.telegramBotName}
                        </button>

                        <h3 style={{ color: "#fff" }}>OR</h3>

                        <h3 style={{ color: "#fff" }}>STEP 01</h3>
                        <p style={{ color: "#F3BA2F" }}>
                          {" "}
                          Open the Tycoon Signals Bot on TG
                        </p>
                        <label style={{ display: "flex", flexWrap: "wrap" }}>
                          <input ref={botNameRefer} value={userInfo.telegramBotName} className="text-center" />
                          <img
                            onClick={copyTGBotnameToClipboard}
                            src={copyicon}
                            style={{ margin: "20px -31px" }}
                          />
                        </label>
                        <p>{isBotNameCopied ? 'Telegram bot name copied successfully' : ''}</p>

                        <h3 style={{ color: "#fff" }}>STEP 02</h3>
                        <p style={{ color: "#F3BA2F" }}>
                          Copy the text below and send it to the Bot to connect your account. That’s it!
                        </p>
                        <label style={{ display: "flex", flexWrap: "wrap" }}>
                          <input ref={refer} value={userInfo.telegramSecret} className="text-center" />
                          <img
                            onClick={copyToClipboard}
                            src={copyicon}
                            style={{ margin: "20px -31px" }}
                          />
                        </label>
                        <p>{isSecretCopied ? 'Secret copied successfully' : ''}</p>
                      </main>
                    </div>
                  </>
                )}
                {isRemoveConnectTelegramModalOpen && (
                  <>
                    <div className={`${styles.overlay}`}></div>
                    <div className={`${styles.modal}`}>
                      <main className={`${styles.Telemodal__main}`}>
                        <h2>Disconnect your Telegram</h2>
                        <i
                          class="fa fa-times"
                          aria-hidden="true"
                          onClick={closeRemoveConnectTelegramModal}
                          style={{ cursor: 'pointer' }}
                        ></i>
                        <br></br>
                        <p>
                          {" "}
                          Are you sure you want to disconnect your telegram account ?{" "}
                        </p>
                        <div className={`${styles.settingPopup} pt-4`}>
                          <button onClick={() => disconnectTelgramChatId()} style={{ color: 'black' }}>Continue</button>
                        </div>
                      </main>
                    </div>
                  </>
                )}

                {isConnectWebhookModalOpen && (
                  <>
                    <div className={`${styles.overlay}`}></div>
                    <div className={`${styles.modal}`}>
                      <main className={`${styles.Telemodal__main} ${styles.text_left}`}>
                        <h2>Webhook URL</h2>
                        <i
                          class="fa fa-times"
                          aria-hidden="true"
                          role="button"
                          onClick={closeConnectWebhookModal}
                          style={{ cursor: 'pointer' }}
                        ></i>

                        <p className={`${styles.text_left} text-white w-100 mt-3`}>
                          {" "}
                          Share a URL endpoint for webhook notifications.{" "}
                        </p>

                        <p className={`${styles.text_left}`}>
                          <input
                            type="text"
                            value={webhookURL}
                            className={`${styles.webhookInput}`}
                            placeholder="Type in the URL"
                            onChange={(e) => {
                              setWebhookURL(e.target.value);
                            }}
                          />
                          <div className="text-danger">
                            <span>{webhookUrlErrorMessage}</span>
                          </div>
                          <div className={`${styles.settingrighttext}`}>
                            <div>
                              <button onClick={() => addUserWebhookUrl()}>
                                Add
                              </button>
                            </div>
                          </div>
                        </p>
                      </main>
                    </div>
                  </>
                )}
                {isRemoveWebhookModalOpen && (
                  <>
                    <div className={`${styles.overlay}`}></div>
                    <div className={`${styles.modal}`}>
                      <main className={`${styles.Telemodal__main}`}>
                        <h2>Disconnect your Webhook</h2>
                        <i
                          class="fa fa-times"
                          aria-hidden="true"
                          onClick={closeRemoveWebhookModal}
                          style={{ cursor: 'pointer' }}
                        ></i>
                        <br></br>
                        <p>
                          {" "}
                          Are you sure you want to remove the webhook URL from your account ?{" "}
                        </p>
                        <div className={`${styles.settingPopup} pt-4`}>
                          <button onClick={() => disconnectWebhookUrl()} style={{ color: 'black' }}>Continue</button>
                        </div>
                      </main>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
          <Footer />
        </div>
      </Fragment >
    </>
  );
};
