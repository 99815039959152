import {
    UPDATE_PERIOD_TYPE, UPDATE_PROFILE_TYPE, UPDATE_SEARCH_NAME,
    UPDATE_SORT_DIRECTION,
    UPDATE_SORT_TYPE,
    UPDATE_STATS_TYPE, UPDATE_SUBSCRIPTION_FLAG, UPDATE_USER,
    UPDATE_CURRENT_PAGE, UPDATE_ROWS_PER_PAGE, UPDATE_TOTAL_ITEMS,
    UPDATE_SKIP_RECORDS, UPDATE_PREVIOUS_PROFILE_TYPE,
    UPDATE_ONLY_PROFITABLE_FILTER, UPDATE_TRACKING_DAYS_FILTER,
    UPDATE_PNL_HIGHER_THAN_FILTER, UPDATE_TRADES_LAST_SEVEN_DAYS_FILTER,
    UPDATE_WIN_RATE_FILTER, UPDATE_IS_FILTERS_ACTIVATED,
    UPDATE_RESET_CUSTOM_NUMBER_FLAG
} from "./leaderboardConstants"

export const updateSelectedProfileType = (value) => {
    return {
        type: UPDATE_PROFILE_TYPE,
        payload: value
    }
}

export const updatePreviousSelectedProfileType = (value) => {
    return {
        type: UPDATE_PREVIOUS_PROFILE_TYPE,
        payload: value
    }
}

export const updateSelectedStatsType = (value) => {
    return {
        type: UPDATE_STATS_TYPE,
        payload: value
    }
}

export const updateSelectedPeriodType = (value) => {
    return {
        type: UPDATE_PERIOD_TYPE,
        payload: value
    }
}

export const updateSelectedSortType = (value) => {
    return {
        type: UPDATE_SORT_TYPE,
        payload: value
    }
}

export const updateSelectedSortDirection = (value) => {
    return {
        type: UPDATE_SORT_DIRECTION,
        payload: value
    }
}

export const updateSearchName = (value) => {
    return {
        type: UPDATE_SEARCH_NAME,
        payload: value
    }
}

export const updateSubscriptionFlag = (value) => {
    return {
        type: UPDATE_SUBSCRIPTION_FLAG,
        payload: value
    }
}

export const updateUser = (value) => {
    return {
        type: UPDATE_USER,
        payload: value
    }
}

export const updateCurrentPage = (page) => {
    return {
        type: UPDATE_CURRENT_PAGE,
        payload: page
    }
};

export const updateRowsPerPage = (rows) => {
    return {
        type: UPDATE_ROWS_PER_PAGE,
        payload: rows
    }
};

export const updateTotalItems = (items) => {
    return {
        type: UPDATE_TOTAL_ITEMS,
        payload: items
    }
};

export const updateSkipRecords = (records) => {
    return {
        type: UPDATE_SKIP_RECORDS,
        payload: records
    }
};

//Filter Actions
export const updateOnlyProfitableFilter = (filterVal) => {
    return {
        type: UPDATE_ONLY_PROFITABLE_FILTER,
        payload: filterVal
    }
};

export const updateTrackingDaysFilter = (filterVal) => {
    return {
        type: UPDATE_TRACKING_DAYS_FILTER,
        payload: filterVal
    }
};

export const updatePnlHigherThanFilter = (filterVal) => {
    return {
        type: UPDATE_PNL_HIGHER_THAN_FILTER,
        payload: filterVal
    }
};

export const updateTradesLastSevenDaysFilter = (filterVal) => {
    return {
        type: UPDATE_TRADES_LAST_SEVEN_DAYS_FILTER,
        payload: filterVal
    }
};

export const updateWinRateFilter = (filterVal) => {
    return {
        type: UPDATE_WIN_RATE_FILTER,
        payload: filterVal
    }
};

export const updateIsFiltersActivated = (filterVal) => {
    return {
        type: UPDATE_IS_FILTERS_ACTIVATED,
        payload: filterVal
    }
};

export const updateResetCustomNumberFlag = (filterVal) => {
    return {
        type: UPDATE_RESET_CUSTOM_NUMBER_FLAG,
        payload: filterVal
    }
};