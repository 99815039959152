import { createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { leaderboardReducer } from './leaderboardReducer';

const persistConfig = {
    key: 'leaderboard',
    storage
};

const persistedReducer = persistReducer(persistConfig, leaderboardReducer);

const store = createStore(persistedReducer);
const persistor = persistStore(store);

export { store, persistor };