import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';

import './index.css';
import App from './App';

import Auth0ProviderWithHistory from './auth/auth0-provider-with-history';
import reportWebVitals from './reportWebVitals';

import { getConfig } from './Services/config.service';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './Redux';

function fetchConfigAndRenderApp() {
  getConfig()
    .then(() => {
      const root = document.getElementById('root');
      createRoot(root).render(
        <Router>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <Auth0ProviderWithHistory>
                <App />
              </Auth0ProviderWithHistory>
            </PersistGate>
          </Provider>
        </Router>
      );
    });
}

fetchConfigAndRenderApp();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();