import React from 'react';
import '../css/Loader.css'; // Import the CSS file with the loader animation

function Loader() {
    return (
        <div class="loader pt-4">
            <div class="loader__spinner"></div>
        </div>
    );
}

export default Loader;
